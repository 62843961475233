import React from 'react';

const Welcome = ({message}) => {
  return (
    <>
    <h3>Welcome</h3>
    {message ? (
      <div>message</div>
    ):(
      <div>Please login to view the College Unbound Community pages.</div>
    )}
    </>
  );
}

export default Welcome;
