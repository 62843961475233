import React, { useState, useEffect } from 'react';
import { Typography, TextField, Button, Slider, Box, Stack } from "@mui/material";
import { useQuery, gql } from "@apollo/client";
import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';

const userquery = gql`
  query Userbyid($idnumber: Int) {
   getuser(idnumber: $idnumber) {
    firstname
    avatar
    email
    id
    idnumber
    lastlogin
    lastname
    orgunit
  }
 }
`;

const userquery2 = gql`
  query Userbyemail($email: String) {
   getuserbyemail(email: $email) {
    firstname
    avatar
    email
    id
    idnumber
    lastlogin
    lastname
    orgunit
  }
 }
`;


const Getusername = (props) => {
  let idnumber = props.idnumber;
  //let email = props.email;
  console.log("idnumber=", idnumber)
  //let idnumber = 2011;
  const { data, loading, error } = useQuery(userquery, { variables: { idnumber } });
  //const { data, loading, error } = useQuery(userquery2, { variables: { email } });
  const [ rows, setRows ] = useState(null);
  const [ dataloaded, setDataLoaded ] = useState(false);
  const [ seluserinfo, setSelUserInfo] = useState(null);

  useEffect(() => {
    if (data) {
      console.log("data loaded")
      setDataLoaded(true);
      setSelUserInfo(data.getuser);
    }
  }, [data]);

  if (loading) {
    return <p>loading...</p>;
  }

  if (error) {
    //console.log(error)
    return <p>Ruh roh! {error.message}</p>;
  }
  

  return (
    <>
      { dataloaded && (
        <Typography sx={{ fontSize: 12, fontStyle: 'italic'}}>{props.preface + seluserinfo.firstname + " " + seluserinfo.lastname}</Typography>
      )}
    
    </>
  );


}

export default Getusername;
