import React, { useState, useEffect } from 'react';
import { Typography, Stack, Link, Box, ToggleButton, ToggleButtonGroup, Divider, Fab, IconButton } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import { useQuery, useMutation, gql } from "@apollo/client";
import { useParams } from "react-router";
import parser from 'html-react-parser';
import Grid from '@mui/material/Grid';
import moment from 'moment';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { NavLink } from "react-router-dom";
import { Formik, Field, Form } from "formik";
import Getuserinfo from "../components/Getuserinfo";
import GetImage from "../components/GetImage";
import GetSignedFileUrl from "../components/GetSignedFileUrl";
import Texteditor from '../components/Texteditor';
import { green, blue, red, yellow } from '@mui/material/colors';
import { useSelector } from 'react-redux';

const blogquery = gql`
  query blog($id: ID) {
    getpost(id: $id) {
      id
      title
      posttext
      author
      authoremail
      otherauthors
      postimage {
       filename
       fileext
       fileuuid
       filedate
       filelink
      }
      file {
        filename
        fileext
        fileuuid
        filedate
        filelink
      }
      tags
      posttype
      accessgroups
      response {
        responderemail
        responsetext
        responsedate
      }
      position
      createdAt
    }
 }
`;

const mutatequery = gql`
  mutation addpostresponse($id: ID, $responderemail: String, $responsetext: String, $responsedate: Float) {
    newpostresponse(id: $id, responderemail: $responderemail, responsetext: $responsetext, responsedate: $responsedate) {
      response {
        responderemail
        responsedate
        responsetext
      }
    }
  }`
;


function Viewstory() {
  let id = sessionStorage.getItem("blogtoview");
  let stags = sessionStorage.getItem("seltags");

  //console.log(id);
  //console.log("stags=", stags)
  const { data, loading, error } = useQuery(blogquery, { variables: { id } });
  const [ addpostresponse, { data2, loading2, error2 } ] = useMutation(mutatequery, { refetchQueries: [{ query: blogquery, variables: { id } }],});
  //const [ addpostresponse, { data2, loading2, error2 } ] = useMutation(mutatequery);
  const [ post, setPost ] = useState(null);
  const [ dataloaded, setDataLoaded ] = useState(false);
  const [ blogid, setBlogId ] = useState(null);
  const [ gotolink, setGoToLink] = useState(null);
  const [ showaddresponse, setShowAddResponse] = useState(false);
  const [ posttext, setPostText] = useState();
  const userinfo = useSelector((state) => state.userinfo);

  const navigate = useNavigate();

  useEffect(() => {
    if (data) {
      setPost(data.getpost);
      setDataLoaded(true);
      //console.log(data.getpost);
      //console.log(sessionStorage.getItem("blogtoview"))
    }
  }, [data]);

  if (loading) {
    return <p>loading...</p>;
  }

  if (error) {
    //console.log(error)
    return <p>Ruh roh! {error.message}</p>;
  }

  const handleChangeLink = (e, value) => {
    setGoToLink(value);
    navigate(value, { replace: true})
  }  
  
  const addresponse = () => {
    let responderemail = userinfo.email;
    let responsetext = posttext;
    let responsedate = Math.floor(Date.now()/1000);
    console.log("id=", id, "responderemail=", responderemail, "responsetext=", responsetext, "responsedate=", responsedate);

    // must use the same variable names as in the query
    addpostresponse({  variables: { id, responderemail, responsetext, responsedate } });
    setShowAddResponse(!showaddresponse);

  }

  const getposttext = (data) => {
    console.log(data);
    setPostText(data);
  }

  return (
    <div align="left">

      <Stack direction="row" alignItems="center" sx={{ justifyContent: 'space-between', width: '500px', ml: 2, pb: 5 }}>
        <ToggleButtonGroup value={gotolink} color="primary" exclusive onChange={handleChangeLink}>
          <ToggleButton value="/getstories">View Stories</ToggleButton>
          <ToggleButton value="/addstory">Add Story</ToggleButton>
        </ToggleButtonGroup>
        <Typography variant="h4">View Story</Typography>
      </Stack>

      { dataloaded && (

      <Grid container direction="column" align='left' width="1000px" sx={{ p: 5}}>
        <Grid item align="left">

          { post.postimage.length>0 && (
          <Box>
            {post.postimage.map((f, index) => (
              <>
              <GetImage filelink={f.filelink} filename={f.filename} width="450"/>
              </>
            ))}
          </Box>
          )}

          <Stack direction="row" width='900px' sx={{ justifyContent: 'space-between' }}>
            <Typography variant="h6">{post.title}</Typography>
            <Getuserinfo email={post.authoremail} first='name' />
          </Stack>
          <Typography>{parser(post.posttext)}</Typography>

          {post.file.length>0 && (

          <Grid container direction="column" align='left' sx={{ mb: 2}}>
            <Grid item>
              <Typography>Files:</Typography>
            </Grid>
            {post.file.map((f, index) => (
              <Grid item>
                <GetSignedFileUrl filelink={f.filelink} filename={f.filename} />
              </Grid>
            ))}
          </Grid>

          )}

          <Typography><b>{ "posted: " + moment(parseFloat(post.createdAt)).format('ddd, M/D/YY, h:mm a') }</b></Typography><br/>

        </Grid>

        { post.response.length>0 && (
          <Grid item sx={{ mt: 2, borderTop: 1, width: 600}}>
           <Box sx={{fontWeight: 'bold', fontSize: 18}}>{post.response.length + " replies"}</Box>
           <Divider />
           {post.response.map((f, index) => (
            <>
             <Stack direction="row" width='600px' sx={{ justifyContent: 'space-between' }}>
               <Box sx={{fontWeight: 'bold', mt: 2}}>{moment.unix(parseFloat(f.responsedate)).format('ddd, M/D/YY, h:mm a')}</Box>
               {/*<Box sx={{fontWeight: 'bold', mt: 2}}>{new Date(f.responsedate).toString()}</Box>*/}
               <Getuserinfo sx={{fontWeight: 'bold', mt: 3}} email={f.responderemail} first='name' />
             </Stack>
             <Box>{parser(f.responsetext)}</Box>
            </>
           ))}
          </Grid>
        )}

         <Grid item>
          { !showaddresponse ? (
            <Fab color="primary" aria-label="add" sx={{ mt: 2}}>
              <AddIcon onClick={() => setShowAddResponse(!showaddresponse)} />
            </Fab>
          ) : (
            <>
            <Stack direction="row" sx={{mt: 2, ml: 2}}>
              <IconButton onClick={addresponse} sx={{ width: 30, height: 30, color: green[500] }}><SaveIcon/></IconButton>
              <IconButton name="cancel" sx={{ width: 30, height: 30 }} onClick={() => { setShowAddResponse(!showaddresponse)}}><CancelIcon /></IconButton>
            </Stack>
            <Texteditor func={getposttext} height="200px" width="550px" />
            </>
          )}
          </Grid>


        </Grid>

        )}
    
    </div>
  );


}

export default Viewstory;


