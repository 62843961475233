import { createTheme } from "@mui/material/styles";

const white = "#ffffff";
const black = "#000000";
//const green = "#bfedad";

export const theme = createTheme({
    palette: {
        common: {
            black: `${black}`,
            white: `${white}`
        },
        primary: {
          light: "#112233",
          main: "#bfedad",
          dark: "#778899",
          contrastText: "#fff"
        },
        secondary: {
          light: "#f0e6e6",
          main: "#c93434",
          dark: "#3c3c3c",
          contrastText: "#000"
        }
    },
});
