import React, { useEffect, useState } from "react";
import base from './base';
import { DataGrid, GridActionsCellItem, gridClasses, GridRowModes, GridToolbarContainer } from '@mui/x-data-grid';
import PropTypes from 'prop-types';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import DeleteIcon from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { Link } from "@mui/material";
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import { Typography, TextField } from "@mui/material";
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import { green, blue, red, yellow } from '@mui/material/colors';
//import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

function Getfacultycards() {
  const [faculty, setFaculty] = useState([]);
  const [loading, setLoading] = useState(false);
  const [rowModesModel, setRowModesModel] = useState({});
  const [pageSize, setPageSize] = useState(25);
  const [rowId, setRowId] = useState(null);
  const [rows, setRows] = useState([]);
  const [allrows, setAllRows] = useState([]);
  const [snackbar, setSnackbar] = useState(null);
  const [searchName, setSearchName] = useState("");
  const [ butOn, setButOn ] = useState([]);
  const [ butOn2, setButOn2 ] = useState([]);
  const [roles, setRoles] = useState('all');

  const handleCloseSnackbar = () => setSnackbar(null);

  const userinfo = useSelector((state) => state.userinfo);

  const navigate = useNavigate();

  useEffect(() => {
    if (userinfo.length==0) {
      navigate("/", { replace: true})
    }
    getrows();
    //console.log(rows);
  }, []);

  useEffect(() => {
    //console.log(searchName.length);
    updaterows(allrows, roles);
  }, [searchName]);

  useEffect(() => {
    //console.log("butOn2=" + butOn2);
  }, [butOn2]);

  useEffect(() => {
    //console.log(rows);
  }, [rows]);

  const getrows = () => {
    base('faculty')
      .select({ view: 'Grid view' })
      .all()
      .then(records => {
          let recs = records.map((e) => (
            e.fields
          ))
          setRows(recs);
          setAllRows(recs);
          initButtons(recs);
      }).catch(err => {
          console.error(err);
      });
  }

  const updaterows = (userows, useroles) => {
    let newrows = [];
    //console.log(searchName.length + " " + useroles)
    userows.forEach(function(row) {
      if (searchName.length===0) {
        if (useroles==='all') {
          newrows.push(row);
        } else if (row.Role!==undefined) {
          if (row.Role.toString().toUpperCase().includes(useroles.toUpperCase())) {
            newrows.push(row);
          }
        }
      } else {
        if (useroles==='all') {
          if ((row.Firstname + " " + row.Lastname).toUpperCase().includes(searchName.toUpperCase())) {
            newrows.push(row);
          }
        } else if (row.Role!==undefined) {
          if ((row.Firstname + " " + row.Lastname).toUpperCase().includes(searchName.toUpperCase()) && row.Role.toString().toUpperCase().includes(useroles.toUpperCase())) {
            newrows.push(row);
          }
        }
      }
    })
    setRows(newrows);
  }


  const editComment = (whichrow,action) => {
    let newarray = Array(allrows.length).fill(false)
    if (action==='Edit') {
      newarray[whichrow] = true;
    }

    setButOn2(newarray);
  }

  const initButtons = (recs) => {
    let butjson = [];
    recs.map((rec) => (
      butjson.push({ "id": rec.facultyid, "show": false})
    ))
    setButOn(butjson);
    setButOn2(Array(recs.length).fill(false));
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    let comments = e.target['comments'].value;
    let id = e.target['txtid'].value;
    base('faculty').update([
      {
        "id": id,
        "fields": {
          "Comments": comments
        }
      }
    ], function(err, records) {
    if (err) {
      console.error(err);
      return;
    }
    records.forEach(function(record) {
      console.log(record.get('Lastname'));
    });
   setSnackbar({ children: 'Saving comment.', severity: 'success' });
   rows.find(row => row.facultyid==id).Comments = comments;
   setRows(rows);
   updaterows(rows, roles);
   setButOn2(Array(allrows.length).fill(false));
   //handleCloseSnackbar();
   });
  }

  const handleChangeRole = (e, value) => {
    setRoles(value);
    updaterows(allrows, value);
  }

  return (
    <div align="left">
    <TableContainer component={Paper}>
    <Table sx={{ border: 0 }}>
      <TableBody>
      <TableRow>
        <TableCell style={{ verticalAlign: 'top' }}>

          <TextField value={searchName} onChange={(e) => setSearchName(e.target.value)} placeholder="Search by Name" variant="standard" sx={{ margin: 2 }}></TextField>

        </TableCell>
        <TableCell style={{ verticalAlign: 'top', textAlign: 'left' }}>

          <ToggleButtonGroup value={roles} color="primary" exclusive onChange={handleChangeRole}>
            <ToggleButton value="all">All Roles</ToggleButton>
            <ToggleButton value="instructional">Instructional</ToggleButton>
            <ToggleButton value="lab">Lab</ToggleButton>
            <ToggleButton value="core">Core</ToggleButton>
            <ToggleButton value="mentor">Mentor</ToggleButton>
          </ToggleButtonGroup>

          </TableCell>
          </TableRow>
        </TableBody>
    </Table>
    </TableContainer>


    <Grid container>
        {rows.map((row, index) => (
          <Grid item key={"item" + row.facultyid}>

            <Card key={"card" + row.facultyid} sx={{ width: 325, minHeight: 200 }}>
              <CardContent>
                <Typography sx={{ fontSize: 10 }} color="text.secondary">{ row.Role!==undefined && row.Role.join(" / ") }</Typography>
                <Typography key={row.facultyid}>{row.Firstname + " " + row.Lastname}</Typography>
                <Typography sx={{ fontSize: 10 }}>{row.OnboardingStatus}</Typography>
                <Typography sx={{ fontSize: 10 }}>{row.TeachingStatus!==undefined && row.TeachingStatus.join(" / ")}</Typography>
                <Typography sx={{ fontSize: 10 }}>{row.CUEmail}</Typography>
                { row.Resume && <Link sx={{ fontSize: 12 }} target="_blank" href={row.Resume}>Resume</Link> }
                {  butOn2[index] ? (
                  <div>
                  <form onSubmit={handleSubmit}>
                    <TextField name="comments" defaultValue={row.Comments} rows={3} multiline variant="standard" sx={{ width: 275}} inputProps={{style: {fontSize: 11}}}></TextField><br/>
                    <IconButton name={"save" + row.facultyid} sx={{ color: green[500], width: 20, height: 20 }} type="submit"><SaveIcon /></IconButton>
                    <IconButton name={"cancel" + row.facultyid} sx={{ width: 20, height: 20, margin: 1 }} onClick={() => { editComment(index,'Cancel')}}><CancelIcon /></IconButton>
                    <input type="hidden" name="txtid" value={row.facultyid}/>
                  </form>
                  </div>
                ):(
                  <div>
                    <Typography sx={{ fontSize: 10 }}>{row.Comments}</Typography>
                    <IconButton name={"edit" + row.facultyid} sx={{ color: yellow[700], width: 20, height: 20 }} onClick={() => { editComment(index,'Edit')}}><EditIcon /></IconButton>
                  </div>
                )}

              </CardContent>
            </Card>

          </Grid>

        ))}
    </Grid>
    {!!snackbar && (
      <Snackbar
        open
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        onClose={handleCloseSnackbar}
        autoHideDuration={1000}
      >
        <Alert {...snackbar} onClose={handleCloseSnackbar} />
      </Snackbar>
    )}


    </div>
  )
}

export default Getfacultycards;
