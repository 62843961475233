import React, { useState, useEffect } from 'react';
import AWS from 'aws-sdk';

const Uploaddel = (filelink) => {

  AWS.config.update( {
   region: process.env.REACT_APP_REGION,            // or whatever your bucket region is
   maxRetries: 5,
   accessKeyId: process.env.REACT_APP_ACCESS,
   secretAccessKey: process.env.REACT_APP_SECRET,
  } );


  let s3Bucket = new AWS.S3();

  console.log(filelink);

  s3Bucket.deleteObject({
      Bucket: 'cu1',
      Key: filelink,
  }, function(err, data) {

      if (err) {
          console.log('Something went wrong');
          return;
      }
  });

}

export default Uploaddel;
