import { configureStore } from '@reduxjs/toolkit'
import userSlice from './components/userSlice';

const store = configureStore({
  reducer: {
    userinfo: userSlice,
  },
});

export default store;
