import React, { useState, useEffect } from 'react';
import { Typography, TextField, Button, Slider, Box, ToggleButton, ToggleButtonGroup, Checkbox, FormGroup, FormControlLabel, Stack, IconButton, Snackbar, Alert } from "@mui/material";
import { useQuery, useMutation, gql } from "@apollo/client";
//import Container from 'react-bootstrap/Container';
import Grid from '@mui/material/Grid';
import Tagliststory from '../components/Tagliststory';
import Texteditor from '../components/Texteditor';
import Uploadfp from '../components/Uploadfp';
import Uploadfpone from '../components/Uploadfpone';
import Sharewithwhom from '../components/Sharewithwhom';
import GetSignedFileUrl from '../components/GetSignedFileUrl';
import GetImage from "../components/GetImage";
import Fab from '@mui/material/Fab';
import { NavLink } from "react-router-dom";
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import Uploaddel from '../components/Uploaddel';
import { red } from '@mui/material/colors';
import "../components/formatlinks.css";

//import Taglist from './Taglist';

const mutatequery = gql`
  mutation editpost($id: ID, $title: String, $posttext: String, $tags: [String], $accessgroups: [String], $position: Int) {
    updatepost(id: $id, title: $title, posttext: $posttext, tags: $tags, accessgroups: $accessgroups, position: $position) {
      id
      title
    }
  }`
;

const deletefilequery = gql`
  mutation removepostfile($id: ID, $fileid: String) {
    deletepostfile(id: $id, fileid: $fileid) 
  }`
;

const deleteimagequery = gql`
  mutation removepostimage($id: ID, $fileid: String) {
    deletepostimage(id: $id, fileid: $fileid) 
  }`
;

const addpostfilequery = gql`
  mutation addpostfile($id: ID, $file: [FileInput]) {
    newpostfile(id: $id, file: $file) {
      filename
    }
  }`
;

const addpostimagequery = gql`
  mutation addpostimage($id: ID, $postimage: [ImageInput]) {
    newpostimage(id: $id, postimage: $postimage) {
      filename
    }
  }`
;


const blogquery = gql`
  query blog($id: ID) {
    getpost(id: $id) {
    id
    title
    posttext
    author
    authoremail
    otherauthors
    postimage {
      id
      filename
      fileext
      fileuuid
      filedate
      filelink
    }
    file {
      id
      filename
      fileext
      fileuuid
      filedate
      filelink
    }
    tags
    posttype
    accessgroups
    response {
      id
      responderemail
      responsetext
      responsedate
    }
    position
    createdAt
  }
 }
`;


function Editstory() {
  let id = sessionStorage.getItem("blogtoview");
  const [ updateid, setUpdateId ] = useState(id)
  const { data, loading, error } = useQuery(blogquery, { variables: { id } });
  const [ editpost, { data2, loading2, error2 } ] = useMutation(mutatequery, { refetchQueries: [{ query: blogquery, variables: { id } }],});
  const [ removepostfile, { data3, loading3, error3 } ] = useMutation(deletefilequery, { refetchQueries: [{ query: blogquery }],});
  const [ addpostfile, { data4, loading4, error4 } ] = useMutation(addpostfilequery);
  const [ addpostimage, { data5, loading5, error5 } ] = useMutation(addpostimagequery);
  const [ removepostimage, { data6, loading6, error6 } ] = useMutation(deleteimagequery, { refetchQueries: [{ query: blogquery }],});
  const [ title, setTitle] = useState("");
  const [ filenames, setFileNames] = useState([]);
  const [ addfiles, setAddFiles] = useState(false);
  //const [ tags, setTags] = useState([]);
  const [ seltags, setSelTags ] = useState(null);
  const [ tagstoset, setTagsToSet ] = useState([]);
  const [ newtag, setNewTag] = useState();
  const [ sharearray, setShareArray] = useState([]);
  const [ posttext, setPostText] = useState();
  const [ position, setPosition] = useState(3);
  const [ post, setPost ] = useState(null);  
  const [ dataloaded, setDataLoaded ] = useState(false);
  const [ texttoset, setTextToSet] = useState("");
  const activeStyle = { color: 'green' };
  const [ sharewith, setShareWith ] = useState();
  const [ gotolink, setGoToLink] = useState(null);
  const [ snackbar, setSnackbar] = useState(null);
  const [ postfiles, setPostFiles] = useState([]);
  const [ mainimages, setMainImages] = useState([]);
  const [ showimage, setShowImage] = useState(true);
  const handleCloseSnackbar = () => setSnackbar(null);

  const userinfo = useSelector((state) => state.userinfo);

  const navigate = useNavigate();

  const handleBlogSubmit = (e) => {
    e.preventDefault();
    console.log(e.target["title"].value);
  }

  const handleTitleChange = (e, value) => {
    setTitle(e.target.value);
  }

  const handleChangePosition = (event, value) => {
    setPosition(value);
  };

  const getposttext = (data) => {
    //console.log(data);
    //console.log("userinfo=", userinfo);
    setPostText(data);
  }

  const getmainimage = (data) => {
    //console.log("Karen");
    setMainImages(data);
  }

  const getfilenames = (data) => {
    setFileNames(data);
  }

  const gettags = (data) => {
    console.log("tags=", data);
    setSelTags(data);
  }

  const getnewtag = (data) => {
    setNewTag(data);
  }

  const getsharearray = (data) => {
    setShareArray(data);
  }


  // save all of the information for the post
  const updatepost = () => {
    let accessgroups = sharearray;
    let tags = seltags;
    let file = filenames;
    let postimage = mainimages;

    console.log("file=", file);

    // must use the same variable names as in the query
    editpost({  variables: { id, title, posttext, tags, accessgroups, position } });
    if (file.length>0) {
      //let { filename, fileext, fileuuid, filedate, filelink } = file[0];
      addpostfile({  variables: { id, file } });
    }
    if (postimage.length>0) {
      //console.log("postimage=", postimage)
      addpostimage({  variables: { id, postimage } });
    }

    //addpost({  variables: { title, posttext, author, authoremail, otherauthors, postimage, file, tags, posttype, accessgroups, position } });    

    setSnackbar({ children: 'Saving story update.', severity: 'success' });
    //navigate("/getblogs", { replace: true})
  }

  useEffect(() => {
    console.log("data", data);
    if (data) {
      setPost(data.getpost);
      setDataLoaded(true);
      
      //console.log(data.getpost);
      //console.log(sessionStorage.getItem("blogtoview"))
    }
  }, [data]);

  useEffect(() => {
    if (post) {
      setTagsToSet(post.tags);
      setTitle(post.title);
      setTextToSet(post.posttext);
      setPosition(post.position);
      setShareWith(post.accessgroups);
      setPostFiles(post.file);
      setMainImages(post.postimage);
      //console.log("post.file=", post.file);
    }
  }, [post]);

  useEffect(() => {
    console.log(sharearray);
  }, [sharearray]);

  useEffect(() => {
    console.log("mainimages=", mainimages);
    let postimage = mainimages;
    if (postimage.length>0) {
      console.log("postimage=", postimage)
      console.log("post.postimage=", post.postimage)
      //addpostimage({  variables: { id, postimage } });
      //setShowImage(true);
    }
    //setSnackbar({ children: 'Image Uploaded.', severity: 'success' });
  }, [mainimages]);

  const handleChangeLink = (e, value) => {
    setGoToLink(value);
    navigate(value, { replace: true})
  }

  const delfile = (index, fileid, filelink) => {
    //console.log("id=", id, "fileid=", fileid);
    setPostFiles(postfiles.filter((f) => f.id!==fileid));
    removepostfile({  variables: { id, fileid } });
    Uploaddel(filelink);
  }

  const delimage = (index, fileid, filelink) => {
    //console.log("id=", id, "fileid=", fileid);
    setMainImages(mainimages.filter((f) => f.id!==fileid));
    removepostimage({  variables: { id, fileid } });
    Uploaddel(filelink);
    setShowImage(false);
  }

  return (
    <div align="left">

      <Stack direction="row" alignItems="center" sx={{ justifyContent: 'space-between', width: '500px', ml: 2, pb: 5 }}>
        <ToggleButtonGroup value={gotolink} color="primary" exclusive onChange={handleChangeLink}>
          <ToggleButton value="/getstories">View Stories</ToggleButton>
          <ToggleButton value="/addstory">Add Story</ToggleButton>
        </ToggleButtonGroup>
        <Typography variant="h4">Edit Story</Typography>
      </Stack>

    <Grid container>
      <Grid item style={{ margin: "10px" }}>
          <TextField name="title" value={title} onChange={handleTitleChange} placeholder="Enter a title for your post." variant="standard" size="large" sx={{ m: 1, width: 730 }}></TextField><br/>
          <Texteditor func={getposttext} setthetext={texttoset} height="700px" width="700px" sx={{ m: 2}}/><br/>
      </Grid>
      <Grid item sx={{ m: 5 }}>
          <Tagliststory functags={gettags} funcnewtag={getnewtag} setthetags={tagstoset} width={"550px"} /><br/><br/>
          <Stack spacing={1} direction="row" sx={{ justifyContent: 'space-between' }}>

            <Box sx={{ fontSize: 14, m: 1 }}>{"Position: " + position}</Box>
            <Slider
              aria-label="Temperature"
              defaultValue={3}
              value={position}
              valueLabelDisplay="auto"
              step={1}
              marks
              min={1}
              max={5}
              onChange={handleChangePosition}
              sx={{width: 200}}
            />
            <Box sx={{ fontSize: 11, ml: 6, display:'flex', alignItems:'center' }}>(Posts with lower numbers appear first.)</Box>


          </Stack><br/>

          <Sharewithwhom funcsharearray={getsharearray} setshare={sharewith} />

          {mainimages.length>0 ? (

          <Grid container direction="column" align='left' sx={{ mb: 2, ml: 1, mt: 2}}>
            <Grid item>
              <Box>Main Images</Box>
            </Grid>
            {mainimages.map((f, index) => (
              <Grid item>
                <Stack spacing={1} direction="row">
                  <IconButton onClick={() => { delimage(index,f.id,f.filelink)}} name={"del" + f.id} key={f.id} sx={{ color: red[500], width: 20, height: 20 }}><DeleteIcon /></IconButton>
                  <GetSignedFileUrl filelink={f.filelink} filename={f.filename} />
                  {post && post.postimage.length>0 && ( <GetImage filelink={f.filelink} filename={f.filename} width="50"/> )}
                </Stack>
              </Grid>
            ))}
          </Grid>
          
          ):(
            <>
            <Box sx={{ ml: 1 }}>Upload Image</Box>
            <Uploadfpone func={getmainimage} />
            <br/><br/>
            </>
          )}

          {post && post.file.length>0 && (

          <Grid container direction="column" align='left' sx={{ mb: 2, ml: 1, mt: 2}}>
            <Grid item>
              <Box>Current Files</Box>
            </Grid>
            {postfiles.map((f, index) => (
              <Grid item>
                <Stack spacing={1} direction="row">
                  <IconButton onClick={() => { delfile(index,f.id,f.filelink)}} name={"del" + f.id} key={f.id} sx={{ color: red[500], width: 20, height: 20 }}><DeleteIcon /></IconButton>
                  <GetSignedFileUrl filelink={f.filelink} filename={f.filename} />
                </Stack>
              </Grid>
            ))}
          </Grid>

          )}

          <Box sx={{ ml: 1 }}>Add Files</Box>
          <Uploadfp func={getfilenames} mfiles={10} />
            <br/><br/>

          <Button onClick={updatepost} variant='contained'>Update Post</Button>

      </Grid>

    </Grid>

    {!!snackbar && (
      <Snackbar
        open
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        onClose={handleCloseSnackbar}
        autoHideDuration={3500}
      >
        <Alert {...snackbar} onClose={handleCloseSnackbar} />
      </Snackbar>
    )}


    </div>
  );
}

export default Editstory;
