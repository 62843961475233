import React, { useState, useEffect, useContext } from 'react';
import './App.css';
import Container from 'react-bootstrap/Container';
//import Header from './Header'
//import Texteditor from './Texteditor'
//import Card from './Card'
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Welcome from './components/Welcome';
import { ThemeProvider } from "@mui/material";

import Addblogpost from './blogs/Addblogpost';
import Getblogs from './blogs/Getblogs';
import Viewblog from "./blogs/Viewblog";
import Editblogpost from './blogs/Editblogpost';
import Yourblog from './blogs/Yourblog';
import Addpost from './blogs/Addpost';
import Getposts from './blogs/Getposts';
import Viewpost from "./blogs/Viewpost";
import Topicalblogs from './blogs/Topicalblogs';

import Addresource from './resources/Addresource';
import Getresources from './resources/Getresources';
import Editresource from "./resources/Editresource";
import Viewresource from "./resources/Viewresource";

import Addstory from './stories/Addstory';
import Getstories from './stories/Getstories';
import Viewstory from "./stories/Viewstory";
import Editstory from './stories/Editstory';

import Tickets from "./techsupport/Tickets";
import Gettickets from "./techsupport/Gettickets";
import Addticket from "./techsupport/Addticket";
import Getticketfollowup from "./techsupport/Getticketfollowup";
import Sharewithwhom from "./Sharewithwhom";

import Viewenrollmentcounts from "./data/Viewenrollmentcounts";
import Viewenrollmentcrosstabs from "./data/Viewenrollmentcrosstabs";
import Viewoutcomescounts from "./data/Viewoutcomescounts";
import Viewoutcomescrosstabs from "./data/Viewoutcomescrosstabs";
import Viewbillablecredits from "./data/Viewbillablecredits";

import Mtheme from './Mtheme'
import GoogleLoginPage from './components/GoogleLoginPage';
import Getfaculty from './faculty/Getfaculty';
import Getfacultycards from './faculty/Getfacultycards';
import User from './User';
import Header from './components/Header';
//import StartEditButtonGrid from './StartEditButtonGrid';
import ServerSidePersistence from './ServerSidePersistence';
//import { UserInfoContext, UserInfoProvider } from './UserInfoContext';

import Testing from './blogs/Testing';

function App() {
  //const user = User();
  const user = JSON.parse(sessionStorage.getItem('user'));
  const userinfo = useSelector((state) => state.userinfo);
  //const userinfo = useContext(userinfoContext);
  //localStorage.clear();

  return (
    <div>
    <ThemeProvider theme={Mtheme}>
    <Container fluid className="App">
      <BrowserRouter>
        {userinfo.length>0 ? (
          <Header />
        ) : (
          <GoogleLoginPage />
        )}
        <Routes>
          <Route path="/" element={<Welcome />} />
          <Route path="/addblogpost" element={<Addblogpost />} />
          <Route path="/editblogpost" element={<Editblogpost />} />
          <Route path="/getblogs" element={<Getblogs />} />
          <Route path="/viewblog" element={<Viewblog />} />
          <Route path="/yourblog" element={<Yourblog />} />
          <Route path="/addpost" element={<Addpost />} />
          <Route path="/getposts" element={<Getposts />} />
          <Route path="/viewpost" element={<Viewpost />} />
          <Route path="/topicalblogs" element={<Topicalblogs />} />
          <Route path="/addresource" element={<Addresource />} />
          <Route path="/getresources" element={<Getresources />} />
          <Route path="/editresource" element={<Editresource />} />
          <Route path="/viewresource" element={<Viewresource />} />
          <Route path="/getfaculty" element={<Getfaculty />} />
          <Route path="/getfacultycards" element={<Getfacultycards />} />
          <Route path="/tickets" element={<Tickets />} />
          <Route path="/gettickets" element={<Gettickets />} />
          <Route path="/getticketfollowup" element={<Getticketfollowup />} />
          <Route path="/addticket" element={<Addticket />} />
          <Route path="/sharewithwhom" element={<Sharewithwhom />} />
          <Route path="/viewenrollmentcounts" element={<Viewenrollmentcounts />} />
          <Route path="/viewenrollmentcrosstabs" element={<Viewenrollmentcrosstabs />} />
          <Route path="/viewoutcomescounts" element={<Viewoutcomescounts />} />
          <Route path="/viewoutcomescrosstabs" element={<Viewoutcomescrosstabs />} />
          <Route path="/viewbillablecredits" element={<Viewbillablecredits />} />
          <Route path="/addstory" element={<Addstory />} />
          <Route path="/getstories" element={<Getstories />} />
          <Route path="/viewstory" element={<Viewstory />} />
          <Route path="/editstory" element={<Editstory />} />
          <Route path="/testing" element={<Testing />} />
        </Routes>
      </BrowserRouter>
    </Container>
    </ThemeProvider>
    </div>
  );
}

export default App;
