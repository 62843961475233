import { useState } from 'react';

export default function User() {

  const getUser = () => {
    const userToken = localStorage.getItem('user');
    return JSON.stringify(userToken)
  };

  const [user, setUser] = useState(getUser());

}
