import React, { useState, useEffect } from 'react';
import AWS from 'aws-sdk';
import { Button } from "@mui/material";
import {v4 as uuidv4} from 'uuid';
import { FilePond, File, registerPlugin } from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';


const Uploadfp2 = (props) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [progress , setProgress] = useState(0);
  const [files, setFiles] = useState([]);
  const [uploadNames, setUploadNames] = useState([]);

  AWS.config.update( {
   region: process.env.REACT_APP_REGION,            // or whatever your bucket region is
   maxRetries: 5,
   accessKeyId: process.env.REACT_APP_ACCESS,
   secretAccessKey: process.env.REACT_APP_SECRET,
  } );

  const handleFile = (e) => {
    setSelectedFile(e.target.files[0]);
  }

  const getkeyname = (filename) => {
    //console.log("filename=", filename);
    let filearray = filename.split(".");
    let fileinfo = new Object();
    fileinfo.filename = filearray[0];
    fileinfo.fileuuid = uuidv4();
    fileinfo.fileext = filearray[1];
    fileinfo.filedate = Date.now();
    fileinfo.filelink = fileinfo.filename + "_" + fileinfo.fileuuid + "." + fileinfo.fileext
    setUploadNames(uploadNames => [...uploadNames, fileinfo]);
    //console.log(uploadNames);
    return fileinfo.filelink;
  }

  const delkeyname = (keyname) => {
    setUploadNames(uploadNames.filter((f) => f.filelink !== keyname));
  }

  let pond = null;

  let s3Bucket = new AWS.S3();

  const onSubmit = () => {
    const formData = new FormData();
    console.log("pond", pond);

    if (pond) {

      const files = pond.getFiles();
      files.forEach((file) => {
        console.log("each file", file);
      });

    }
  };

  useEffect(() => {
    //console.log("uploadNames=", uploadNames);
    props.func(uploadNames);
  }, [uploadNames]);



  return (
    <div className="App">
        <FilePond
            files={files}
            ref={(ref) => {
              pond = ref;
            }}
            onupdatefiles={setFiles}
            allowMultiple={true}
            maxFiles={10}
            name="files"
            labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
            server={{
              process: function(fieldName, file, metadata, load, error, progress, abort) {
                  s3Bucket.upload({
                      Bucket: 'cu1',
                      Key: getkeyname(file.name),
                      Body: file,
                      ContentType: file.type,
                  }, function(err, data) {

                      if (err) {
                          error('Something went wrong');
                          return;
                      }
                      // pass file unique id back to filepond
                      load(data.Key);
                  });
              },

              revert: (uniqueFileId, load, error) => {
                s3Bucket.deleteObject({
                    Bucket: 'cu1',
                    Key: uniqueFileId,
                }, function(err, data) {

                    if (err) {
                        error('Something went wrong');
                        return;
                    }
                    // pass file unique id back to filepond
                    load();
                    delkeyname(uniqueFileId);
                });

                 load();
               }
            }}
        />

    </div>
  );
}

export default Uploadfp2;
