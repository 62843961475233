import { createSlice } from '@reduxjs/toolkit'
// Slice
const userSlice = createSlice({
  name: 'userinfo',
  initialState: [],
  reducers: {
    setuser: (state, action) => {
      //state.userinfo = action.payload;
      //state.push(state.userinfo);
      return action.payload;
    },
  },
});

export const { setuser } = userSlice.actions;

export default userSlice.reducer;
