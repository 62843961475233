import React, { useState, useEffect } from 'react';
import { Typography, TextField, Button, Slider, Box, Stack } from "@mui/material";
import { useQuery, gql } from "@apollo/client";
import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';

const userquery = gql`
  query Userbyemail($email: String) {
   getuserbyemail(email: $email) {
    firstname
    avatar
    email
    id
    idnumber
    lastlogin
    lastname
    orgunit
  }
 }
`;


const Getuserinfo = (props) => {
  let email = props.email;
  const { data, loading, error } = useQuery(userquery, { variables: { email } });
  const [ rows, setRows ] = useState(null);
  const [ dataloaded, setDataLoaded ] = useState(false);
  const [ seluserinfo, setSelUserInfo] = useState(null);

  useEffect(() => {
    if (data) {
      setDataLoaded(true);
      setSelUserInfo(data.getuserbyemail);
    }
  }, [data]);

  if (loading) {
    return <p>loading...</p>;
  }

  if (error) {
    //console.log(error)
    return <p>Ruh roh! {error.message}</p>;
  }
  

  return (
    <>
      { dataloaded && (

        props.first==='avatar' ? (

        <Stack direction='row' sx={{display:'flex', alignItems:'center'}} >
           <Avatar
            sx={{ width: 40, height: 40, mr: 2 }}
            alt={seluserinfo.firstname}
            src={seluserinfo.avatar}
           />          
           <b><i>{seluserinfo.firstname + " " + seluserinfo.lastname}</i></b>
        </Stack>

      ):(
        <Stack direction='row' sx={{display:'flex', alignItems:'center'}}>
           <b><i>{seluserinfo.firstname + " " + seluserinfo.lastname}</i></b>
           <Avatar
            sx={{ width: 40, height: 40, ml: 2 }}
            alt={seluserinfo.firstname}
            src={seluserinfo.avatar}
           />          
        </Stack>

      ))}
    
    </>
  );


}

export default Getuserinfo;
