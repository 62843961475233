import React from 'react';
//import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css'
import './index.css';
import App from './App';
import { render } from "react-dom";
import {
  ApolloClient,
  InMemoryCache,
  HttpLink,
  ApolloProvider,
} from "@apollo/client";

import store from './store';
import { Provider } from 'react-redux';

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: new HttpLink({
  uri: "https://cuapi.collegeunbound.online/api",
  }),
});

render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <Provider store={store}>
        <App />
      </Provider>
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
