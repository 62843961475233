import React, { useState, useEffect } from 'react';
import { Typography, TextField, Select, MenuItem, Button, Slider, Box, ToggleButton, ToggleButtonGroup, Checkbox, FormGroup, FormControlLabel } from "@mui/material";
import { useQuery, useMutation, gql } from "@apollo/client";
//import Container from 'react-bootstrap/Container';
import Grid from '@mui/material/Grid';
import Taglist from '../components/Taglist';
import Texteditor from '../components/Texteditor';
import Uploadfp from '../components/Uploadfp';
import Sharewithwhom from '../components/Sharewithwhom';
import GetSignedFileUrl from '../components/GetSignedFileUrl';
import Fab from '@mui/material/Fab';
import sortArray from 'sort-array';
import { NavLink } from "react-router-dom";
import Stack from '@mui/material/Stack';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import "../components/formatlinks.css";

//import Taglist from './Taglist';

const mutatequery = gql`
  mutation addpost($title: String, $posttext: String, $author: String, $authoremail: String, $otherauthors: [String], $file: [FileInput], $tags: [String], $posttype: [String], $blog: String, $accessgroups: [String], $position: Int) {
    newpost(title: $title, posttext: $posttext, author: $author, authoremail: $authoremail, otherauthors: $otherauthors, file: $file, tags: $tags, posttype: $posttype, blog: $blog, accessgroups: $accessgroups, position: $position) {
      author
      posttext
    }
  }`
;

const blogquery = gql`
  query blogs {
    getpostscats(accessgroups: ["staff","faculty"], posttype: ["blog"]) {
     id
     title
     author
     authoremail
     posttext
     position
     file {
      filename
      fileext
      fileuuid
      filedate
      filelink
     }
     accessgroups
     blog
     otherauthors
     posttype
     tags
     createdAt
  }
 }
`;

const blog2 = gql`
  query blogs2 {
   getblogs {
    blogtitle
    blogdescription
  }
}
`;

const blognamesquery = gql`
  query blog($idnumber: Int) {
    getuserblogs(idnumber: $idnumber) {
      id
      idnumber
      blogtitle
      blogdescription
    }
  }
`;

function Addpost() {
  let idnumber = sessionStorage.getItem("otherinfo")*1;
  const blognamesq = useQuery(blognamesquery, { variables: { idnumber } });
  const [ addpost, { data2, loading2, error2 } ] = useMutation(mutatequery, { refetchQueries: [{ query: blogquery }],});
  //const [ addpost, { data2, loading2, error2 } ] = useMutation(mutatequery);
  const [ title, setTitle] = useState();
  const [ mainimage, setMainImage] = useState([]);
  const [ filenames, setFileNames] = useState([]);
  const [ addfiles, setAddFiles] = useState(false);
  const [ seltags, setSelTags ] = useState(null);
  const [ newtag, setNewTag] = useState();
  const [ sharearray, setShareArray] = useState();
  const [ posttext, setPostText] = useState();
  const [ position, setPosition] = useState(3);
  const activeStyle = { color: 'green' };
  const [ gotolink, setGoToLink] = useState("/addpost");
  const [ blognames, setBlognames ] = useState([]);
  const [ showblognames, setShowblognames] = useState(false);
  const [ blogsel, setBlogsel] = useState("66de072ffcc8fe62b1a9253b");

  const userinfo = useSelector((state) => state.userinfo);

  const navigate = useNavigate();

  const handleBlogSubmit = (e) => {
    e.preventDefault();
    console.log(e.target["title"].value);
  }

  const handleBlogSelect = (e, value) => {
    console.log(e.target.value);
    setBlogsel(e.target.value);
  }

  const handleTitleChange = (e, value) => {
    setTitle(e.target.value);
  }

  const handleChangePosition = (event, value) => {
    setPosition(value);
  };

  const getposttext = (data) => {
    //console.log(data);
    //console.log("userinfo=", userinfo);
    setPostText(data);
  }

  const getfilenames = (data) => {
    setFileNames(data);
  }

  const gettags = (data) => {
    console.log("tags=", data);
    setSelTags(data);
  }

  const getnewtag = (data) => {
    setNewTag(data);
  }

  const getmainimage = (data) => {
    setMainImage(data);
  }  

  const getsharearray = (data) => {
    setShareArray(data);
  }

  // save all of the information for the post
  const savepost = () => {
    let author = userinfo.name;
    let authoremail = userinfo.email;
    let file = filenames;
    let posttype = ["blog"];
    let blog = blogsel;
    let accessgroups = sharearray;
    let tags = seltags;
    let postimage = mainimage ;

    console.log("filenames=", filenames);
    console.log("tags=", tags);
    console.log("blog=", blog);

    addpost({  variables: { title, posttext, author, authoremail, postimage, file, tags, posttype, blog, accessgroups, position } });

    navigate("/getposts", { replace: true})
  }

  //console.log("data3=", data3);
  useEffect(() => {
    if (blognamesq.data) {
      let blognamespre = blognamesq.data.getuserblogs;
      setBlognames(sortArray(blognamespre.map((blognamespre) => blognamespre.blogtitle)));
   }
  }, [blognamesq.data]);

  useEffect(() => {
    //console.log("filenames=", filenames);
  }, [filenames]);

  useEffect(() => {
    console.log("blognames=", blognames);
    if (blognames.length>0) {
      setShowblognames(true);
    } 
  }, [blognames]);

  useEffect(() => {
    console.log(sharearray);
  }, [sharearray]);

  const handleChangeLink = (e, value) => {
    setGoToLink(value);
    navigate(value, { replace: true})
  }

  return (
    <div align="left">

      <Stack direction="row" alignItems="center" sx={{ justifyContent: 'space-between', width: '600px', ml: 2, pb: 5 }}>
        <ToggleButtonGroup value={gotolink} color="primary" exclusive onChange={handleChangeLink}>
          <ToggleButton value="/getposts">View Posts</ToggleButton>
          <ToggleButton value="/addpost">Add Post</ToggleButton>
          <ToggleButton value="/topicalblogs">Blogs</ToggleButton>
        </ToggleButtonGroup>
        <Typography variant="h5">Add Post</Typography>
      </Stack>

    <Grid container>
      <Grid item style={{ margin: "10px" }}>
          <TextField name="title" value={title} onChange={handleTitleChange} placeholder="Enter a title for your post." variant="standard" size="large" sx={{ m: 1, width: 730 }}></TextField><br/>
          <Texteditor func={getposttext} height="700px" width="700px" sx={{ m: 2}}/><br/>
      </Grid>
      <Grid item sx={{ m: 5 }}>
          <Taglist functags={gettags} funcnewtag={getnewtag} width={"375px"} /><br/><br/>
          <Stack spacing={1} direction="row" sx={{ justifyContent: 'space-between' }}>

            <Box sx={{ m: 1 }}>{"Position: " + position}</Box>
            <Slider
              aria-label="Temperature"
              defaultValue={3}
              value={position}
              valueLabelDisplay="auto"
              step={1}
              marks
              min={1}
              max={5}
              onChange={handleChangePosition}
              sx={{width: 200}}
            />
            <Box sx={{ fontSize: 11, ml: 6, display:'flex', alignItems:'center' }}>(Posts with lower numbers appear first.)</Box>


          </Stack><br/>

          <Sharewithwhom funcsharearray={getsharearray}/>

          { showblognames && (

          <>
          <Stack spacing={1} direction="row" sx={{ justifyContent: 'space-between' }}>
          <Box sx={{ m: 1 }}>Blog: </Box>
          <Select value={blogsel} sx={{ margin: 1,  width: 375}} defaultValue="66de072ffcc8fe62b1a9253b" onChange={handleBlogSelect} variant="standard">
            <MenuItem key="blognamesnone" value="66de072ffcc8fe62b1a9253b">None</MenuItem>
            {blognames.map((option, index) => (
              <MenuItem key={option} value={option}>{option}</MenuItem>
            ))}
          </Select>
          </Stack>
          </>

          )}          

          <br/>
          <Box sx={{ ml: 1 }}>Upload Image</Box>
          <Uploadfp func={getmainimage}/>
          <br/>

          <Box sx={{ ml: 1, mt: 5 }}>Add Files</Box>
          <Uploadfp func={getfilenames}/>
            <br/><br/>
          <Button onClick={savepost} variant='contained'>Save Post</Button>

      </Grid>

      <Grid item>

      </Grid>

    </Grid>

    </div>
  );
}

export default Addpost;
