import React, { useEffect, useState } from "react";
import base from './base';
import { DataGrid, GridActionsCellItem, gridClasses, GridRowModes, GridToolbarContainer } from '@mui/x-data-grid';
import PropTypes from 'prop-types';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import DeleteIcon from '@mui/icons-material/Delete';
import { Link } from "@mui/material";
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { green, blue, red } from '@mui/material/colors';
//import Stack from '@mui/material/Stack';

function ReturnLink(params) {
  let rlink = params.value;
  if (rlink === undefined) {
    return (
      '❌'
    )
  } else {
    return (
      <div>
        <Link target="_blank" href={rlink}>
          View
        </Link>
      </div>
    )
  }
}

function Getfaculty() {
  const [faculty, setFaculty] = useState([]);
  const [loading, setLoading] = useState(false);
  const [rowModesModel, setRowModesModel] = useState({});
  const [pageSize, setPageSize] = useState(25);
  const [rowId, setRowId] = useState(null);
  const [rows, setRows] = useState([]);
  const [snackbar, setSnackbar] = useState(null);

  const columns = [
    {
      field: 'actions',
      headerName: 'Actions',
      type: 'actions',
      width: 125,
      cellClassName: 'actions',
      getActions: (params) => {
        const isInEditMode = rowModesModel[params.id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                width: 25,
                height: 25,
                color: green[500]
              }}
              onClick={handleSaveClick(params)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(params.id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            sx={{
              width: 25,
              height: 25,
              color: blue[500]
            }}
            className="textPrimary"
            onClick={handleEditClick(params.id)}
            color="inherit"
          />,
        ];
      },
    },
    { field: 'Firstname', headerName: 'First Name', width: 200},
    { field: 'Lastname', headerName: 'Last Name', width: 200},
    { field: 'Role', headerName: 'Role', width: 200},
    { field: 'CUEmail', headerName: 'E-mail', width: 200},
    {
      field: 'Resume',
      headerName: 'Resume',
      renderCell: ReturnLink,
    },
    { field: 'Comments', headerName: 'Comments', width: 250, editable: true},
  ];

  function EditToolbar(props) {
    const { setRows, setRowModesModel } = props;

  }

  EditToolbar.propTypes = {
    setRowModesModel: PropTypes.func.isRequired,
    setRows: PropTypes.func.isRequired,
  };



  const rowEditStart = (newRow) => {
      //console.log(newRow.id);
      //console.log("rowId " + rowId);
  };

  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    let id = rowId;
    let comments = updatedRow.Comments;
    //editticket({  variables: { id, problemdescription, status} });
    console.log(id, comments);
    base('faculty').update([
      {
        "id": id,
        "fields": {
          "Comments": comments
        }
      }
    ], function(err, records) {
    if (err) {
      console.error(err);
      return;
    }
    records.forEach(function(record) {
      console.log(record.get('Lastname'));
    });
   });
   return updatedRow;
}


  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const handleProcessRowUpdateError = React.useCallback((error) => {
    setSnackbar({ children: error.message, severity: 'error' });
  }, []);

  const handleRowEditStart = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowEditStop = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (params) => () => {
    setRowModesModel({ ...rowModesModel, [params.id]: { mode: GridRowModes.View } });
    setRowId(params.id)
  };

  const handleDeleteClick = (id) => () => {
    setRows(rows.filter((row) => row.id !== id));
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    console.log("id=" + id);
    console.log(rows);

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };


  useEffect(() => {
    base('faculty')
      .select({ view: 'Grid view' })
      .all()
      .then(records => {
          let recs = records.map((e) => (
            e.fields
          ))
          setRows(recs);
      }).catch(err => {
          console.error(err);
      }); }, []);

  return (
    <div>
      <DataGrid
        autoHeight
        getRowId={(row) => row.facultyid}
        initialState={{
          sorting: {
            sortModel: [{ field: 'Lastname', sort: 'asc' }],
          },
          pagination: { paginationModel: { pageSize: 25 } }
        }}
        rows={rows}
        columns={columns}
        editMode="row"
        rowsPerPageOptions={[5, 10, 20]}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        processRowUpdate={processRowUpdate}
        onRowEditStart={rowEditStart}
        onProcessRowUpdateError={handleProcessRowUpdateError}
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStart={handleRowEditStart}
        onRowEditStop={handleRowEditStop}
        slots={{
          toolbar: EditToolbar,
        }}
        slotProps={{
          toolbar: { setRows, setRowModesModel },
        }}
      />
    </div>
  );
}

export default Getfaculty;
