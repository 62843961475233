import React, { useEffect, useState } from 'react';
import { Button, Typography, Box, ToggleButton, ToggleButtonGroup, Stack } from "@mui/material";
import { gql, useMutation } from '@apollo/client';
import MenuItem from '@mui/material/MenuItem';
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
//import { DatePicker } from 'formik-mui';

import { Formik, Form, Field } from "formik";
import { TextField } from 'formik-mui';
import { useSelector } from 'react-redux';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import User from '../User';
import Welcome from '../components/Welcome';
import { useNavigate } from 'react-router-dom';
//import { useSelector } from 'react-redux';


const mutatequery = gql`
  mutation addticket($requestby: String!, $email: String!, $requestdate: String, $needbydate: String, $problemdescription: String, $problemnotes: String, $assignedto: String, $category: String, $status: String) {
    newticket (requestby: $requestby, email: $email, requestdate: $requestdate, needbydate: $needbydate, problemdescription: $problemdescription, problemnotes: $problemnotes, assignedto: $assignedto, category: $category, status: $status) {
      id
      requestby
      email
      requestdate
      needbydate
      problemdescription
      problemnotes
      assignedto
      category
      status
    }
  }
`;

const mutatequery2 = gql`
  mutation addticketfollowup($id: ID, $followupnotes: String, $followupdate: String) {
    newticketfollowup (id: $id, followupnotes: $followupnotes, followupdate: $followupdate) {
      followup {
        followupnotes
        followupdate
      }
    }
  }
`;


const ticketquery = gql`
  query tickets {
    gettickets {
      id
      requestby
      email
      requestdate
      needbydate
      problemdescription
      assignedto
      category
      status
    }
  }
`;
const validationSchema = Yup.object().shape({
  requestby: Yup.string().required('requestby is required'),
  email: Yup.string()
    .required('Email is required')
    .email('Email is invalid'),
});

const initialValues = {
  requestby: "",
  email: "",
  problemdescription: "",
  problemnotes: "",
  assignedto: "Not assigned yet",
  category: "General",
  status: "Not started",
}


const defaultValues = { assignedto: "Not assigned yet", category: "General", status: "Not started" }

const Addticket = () => {
  //const { register, handleSubmit, reset, control, setValue, formState: { errors, isSubmitSuccessful } } = useForm({ defaultValues: defaultValues,  resolver: yupResolver(validationSchema)});
  const [ addticket, { data, loading, error } ] = useMutation(mutatequery, { refetchQueries: [{ query: ticketquery }],});
  const [ addticketfollowup, { data2, loading2, error2 } ] = useMutation(mutatequery2);
  const [submittedData, setSubmittedData] = useState({});
  const [snackbar, setSnackbar] = useState(null);
  const [ gotolink, setGoToLink] = useState("/addticket");
  const now = new Date();
  const today = now.setHours(9,0,0,0);
  const tomorrow = new Date(now.setDate(now.getDate() + 1));
  const nextWeek = new Date(now.setDate(now.getDate() + 7)).setHours(18,0,0,0);
  const [startDateValue, setStartDateValue] = React.useState(tomorrow);

  const handleCloseSnackbar = () => setSnackbar(null);

  const userinfo = useSelector((state) => state.userinfo);
  //console.log(JSON.stringify(userinfo));

  const navigate = useNavigate();

  const onSubmit = (data, e) => {

    const { requestby, email, requestdate, needbydate, problemdescription, problemnotes, assignedto, category, status } = data
    setSubmittedData(data);

    let followupnotes = "Karen";
    let followupdate = "4/8/23";
    let id = "642fb6517011e57b62720e0f";

    addticket({  variables: { requestby, email, requestdate, needbydate, problemdescription, problemnotes, assignedto, category, status } });
    addticketfollowup({  variables: { id, followupnotes, followupdate } });

  }

  const user = JSON.parse(sessionStorage.getItem('user'));


  const handleChangeLink = (e, value) => {
    setGoToLink(value);
    navigate(value, { replace: true})
  }


  return (
    <div>


    <Stack direction="row" alignItems="center" sx={{ justifyContent: 'space-between', width: '700px', ml: 2, pb: 5 }}>
      <ToggleButtonGroup value={gotolink} color="primary" exclusive onChange={handleChangeLink}>
        <ToggleButton value="/gettickets">Tickets</ToggleButton>                                                                                                                                    
        <ToggleButton value="/getticketfollowup">Followup</ToggleButton>
        <ToggleButton value="/addticket">Add Tickets</ToggleButton>
      </ToggleButtonGroup>
      <Typography variant="h4" sx={{ ml: 2}}>Create New Ticket</Typography>
    </Stack>


    {userinfo.length==0 ? <Welcome /> :
    (
    <Box sx={{ fontSize: 11, ml: 20, display:'flex', alignItems:'left', width: 500 }} noValidate >
      {!!snackbar && (
        <Snackbar
          open
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          onClose={handleCloseSnackbar}
          autoHideDuration={6000}
        >
          <Alert {...snackbar} onClose={handleCloseSnackbar} />
        </Snackbar>
      )}

     <Formik
       initialValues={initialValues}
       onSubmit={(values, { setSubmitting }) => {
         setTimeout(() => {
           console.log(values);
           const { requestby, email, requestdate, needbydate, problemdescription, problemnotes, assignedto, category, status } = values;
           console.log("requestby=", requestby);
           addticket({  variables: { requestby, email, requestdate, needbydate, problemdescription, problemnotes, assignedto, category, status } });
           setSubmitting(false);
         }, 400);
       }}
     >

     {({
       values,
       errors,
       touched,
       handleChange,
       handleBlur,
       handleSubmit,
       isSubmitting,
     }) => (

      <form onSubmit={handleSubmit}>
        <div>
          <Field component={TextField} name="requestby" type={'text'} sx={{ margin: 3, width: 500}} label="Person's Name" variant="standard" />
          <br/>
          <Field component={TextField} name="email" type={'text'} sx={{ margin: 3,  width: 500}} label="E-mail" variant="standard" />
          <br/>
          <Field component={TextField} name="problemdescription" type={'text'} sx={{ margin: 3,  width: 500}} label="Description of Problem" rows={5} multiline variant="standard" /><br/>
          <Field component={TextField} name="problemnotes" sx={{ margin: 3,  width: 500}} label="Problem Notes" rows={5} multiline variant="standard" /><br/>
          <Field component={TextField} name="assignedto" type={'text'} sx={{ margin: 3, textAlign:"left", width:500}} label="Person Responding" defaultValue="Not assigned yet" select variant="standard">
            <MenuItem value="Not assigned yet">Not assigned yet</MenuItem>
            <MenuItem value="Ashley">Ashley</MenuItem>
            <MenuItem value="Elsie">Elsie</MenuItem>
            <MenuItem value="Herb">Herb</MenuItem>
            <MenuItem value="Lance">Lance</MenuItem>
            <MenuItem value="Mike">Mike</MenuItem>
          </Field><br/><br/>
          <Field component={TextField} name="category" type={'text'} sx={{ margin: 3, textAlign:"left", width:500}} label="Category" defaultValue="General" select variant="standard">
            <MenuItem value="General">General</MenuItem>
            <MenuItem value="Moodle">Moodle</MenuItem>
            <MenuItem value="Google Access">Google Access</MenuItem>
            <MenuItem value="Campus Cafe">Campus Cafe</MenuItem>
            <MenuItem value="Digication">Digication</MenuItem>
          </Field><br/><br/>
          <Field component={TextField} name="status" type={'text'} sx={{ margin: 3, textAlign:"left", width:500}} label="Status" defaultValue="Not started" select variant="standard">
            <MenuItem value="Not started">Not started</MenuItem>
            <MenuItem value="In process">In process</MenuItem>
            <MenuItem value="Waiting for information">Waiting for information</MenuItem>
            <MenuItem value="Done">Done</MenuItem>
          </Field><br/><br/>

          <Box sx={{ display:'flex', justifyContent: 'flex-start' }}>
            <Typography sx={{ ml: 3, mb: 3, textAlign:"left", width:170}}>Requested Date</Typography>
            <Field name="requestdate" label="Request Date">
              {({ field, form }) => (
                <DatePicker
                  showIcon
                  icon="fa fa-calendar"
                  label="Request Date"
                  {...field}
                  selected={field.value}
                  onChange={(requestdate) => form.setFieldValue(field.name, requestdate)}
                />
              )}
            </Field>
          </Box>

          <Box sx={{ display:'flex', justifyContent: 'flex-start' }}>
            <Typography sx={{ ml: 3, mb:3, textAlign:"left", width:170}}>Need by Date</Typography>
            <Field name="needbydate">
              {({ field, form }) => (
                <DatePicker
                  {...field}
                  selected={field.value}
                  onChange={(requestdate) => form.setFieldValue(field.name, requestdate)}
                />
              )}
            </Field>
          </Box>

          <br/><br/>
          <Button type='submit'>Submit Ticket</Button>
        </div>
      </form>

      )}

     </Formik>

      </Box>
    )}
    </div>
  )
}

export default Addticket;
