import React, { useState, useEffect } from "react";
import { useQuery, useMutation, gql } from "@apollo/client";
import { DataGrid, GridActionsCellItem, gridClasses, GridRowModes, GridToolbarContainer } from '@mui/x-data-grid';
import PropTypes from 'prop-types';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import DeleteIcon from '@mui/icons-material/Delete';
import { Typography, Box } from "@mui/material";
import { Link } from "react-router-dom";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import Stack from '@mui/material/Stack';
import moment from 'moment';

import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { green, blue, red } from '@mui/material/colors';
import Ticketsave from './Ticketsave';
import { useNavigate } from 'react-router-dom';

const ticketquery = gql`
  query tickets {
    gettickets {
      id
      requestby
      email
      requestdate
      needbydate
      problemdescription
      assignedto
      category
      status
    }
  }
`;

const mutatequery = gql`
  mutation editticket($id: ID, $requestby: String!, $email: String!, $requestdate: String, $needbydate: String, $problemdescription: String, $problemnotes: String, $assignedto: String, $category: String, $status: String) {
    updateticket (id: $id, requestby: $requestby, email: $email, requestdate: $requestdate, needbydate: $needbydate, problemdescription: $problemdescription, problemnotes: $problemnotes, assignedto: $assignedto, category: $category, status: $status) {
      id
      requestby
      email
      requestdate
      needbydate
      problemdescription
      problemnotes
      assignedto
      category
      status
    }
  }
`;

const deletequery = gql`
  mutation removeticket($id: ID) {
    deleteticket (id: $id)
  }
`;


function EditToolbar(props) {
  const { setRows, setRowModesModel } = props;

}

EditToolbar.propTypes = {
  setRowModesModel: PropTypes.func.isRequired,
  setRows: PropTypes.func.isRequired,
};


function Gettickets() {
  const { data, loading, error } = useQuery(ticketquery);
  const [pageSize, setPageSize] = useState(5);
  const [rowId, setRowId] = useState(null);
  const [snackbar, setSnackbar] = useState(null);
  const [rowModesModel, setRowModesModel] = useState({});
  const [rows, setRows] = useState(null);
  const [dataloaded, setDataLoaded] = useState(false);
  const [ editticket, { data2, loading2, error2 } ] = useMutation(mutatequery);
  const [ removeticket, { data3, loading3, error3 } ] = useMutation(deletequery, { refetchQueries: [{ query: ticketquery }],});
  const [currentid, setCurrentId] = useState(null);
  const [ gotolink, setGoToLink] = useState("/gettickets");

  const navigate = useNavigate();

  const columns = [
    {
      field: 'actions',
      headerName: 'Actions',
      type: 'actions',
      width: 125,
      cellClassName: 'actions',
      getActions: (params) => {
        const isInEditMode = rowModesModel[params.id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                width: 25,
                height: 25,
                color: green[500]
              }}
              onClick={handleSaveClick(params)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(params.id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            sx={{
              width: 25,
              height: 25,
              color: blue[500]
            }}
            className="textPrimary"
            onClick={handleEditClick(params.id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            sx={{
              width: 25,
              height: 25,
              color: red[500]
            }}
            onClick={handleDeleteClick(params.id)}
            color="inherit"
          />,
        ];
      },
    },
    { field: 'requestby', headerName: 'Name', width: 100, editable: true},
    { field: 'problemdescription', headerName: 'Description', width: 175, editable: true},
    {
      field: 'requestdate', headerName: 'Request Date', width: 125, type: 'date', editable: true,
      valueGetter: ({ value }) => value && new Date(value),
      renderCell: (params) =>
        moment(params.row.requestdate).format('MM/DD/YY'),
    },
    { field: 'needbydate', headerName: 'Need by Date', width: 125, type: 'date', editable: true,
      valueGetter: ({ value }) => value && new Date(value),
      renderCell: (params) =>
        moment(params.row.needbydate).format('MM/DD/YY'),
    },
    { field: 'assignedto', headerName: 'Assigned To',
      type: 'singleSelect', width: 140, valueOptions: ['Not assigned yet','Ashley','Raquel','Mike'],
      editable: true
    },
    { field: 'category', headerName: 'Category', width: 125},
    { field: 'status', headerName: 'Status', width: 125,
      type: 'singleSelect', width: 125, valueOptions: ['Not started','In process','Waiting for information','Done'],
      editable: true
    },
  ]

  const handleCloseSnackbar = () => setSnackbar(null);

  const handleRowEditStart = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowEditStop = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (params) => () => {
    setRowModesModel({ ...rowModesModel, [params.id]: { mode: GridRowModes.View } });
    setRowId(params.id)
  };

  const handleDeleteClick = (id) => () => {
    setRows(rows.filter((row) => row.id !== id));
    removeticket({  variables: { id } });
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    //setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    console.log("rowId=" + rowId);
    //console.log(updatedRow);
    let id = rowId;
    let requestby = updatedRow.requestby;
    let email = updatedRow.email;
    let requestdate = updatedRow.requestdate;
    let needbydate = updatedRow.needbydate;
    let problemdescription = updatedRow.problemdescription;
    let assignedto = updatedRow.assignedto;
    let category = updatedRow.category;
    let status = updatedRow.status;
    editticket({  variables: { id, requestby, email, requestdate, needbydate, problemdescription, assignedto, category, status} });
    //editticket({  variables: { id, problemdescription, status} });
    console.log(id, requestby, email, requestdate, needbydate, problemdescription, assignedto, category, status);
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const editUser = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const saveUser = React.useCallback(
    (params) => () => {
      console.log("id " + params.id)
    },
    [],
  );

  const deleteUser = React.useCallback(
    (params) => () => {
      console.log("id " + params.id)
    },
    [],
  );

  const rowEditStart = (newRow) => {
      //console.log(newRow.id);
      //console.log("rowId " + rowId);
  };

  useEffect(() => {
    console.log("rowId " + rowId)
  }, [rowId]);

  useEffect(() => {
    if (data) {
      setRows(data.gettickets);
      setDataLoaded(true);
    }
  }, [data]);

  const handleProcessRowUpdateError = React.useCallback((error) => {
    setSnackbar({ children: error.message, severity: 'error' });
  }, []);


  if (loading) {
    return <p>loading...</p>;
  }

  if (error) {
    //console.log(error)
    return <p>Ruh roh! {error.message}</p>;
  }

  const handleChangeLink = (e, value) => {
    setGoToLink(value);
    navigate(value, { replace: true})
  }

  return (
    <>

      <Stack direction="row" alignItems="center" sx={{ justifyContent: 'space-between', width: '700px', ml: 2, pb: 5 }}>
        <ToggleButtonGroup value={gotolink} color="primary" exclusive onChange={handleChangeLink}>
          <ToggleButton value="/gettickets">Tickets</ToggleButton>
          <ToggleButton value="/getticketfollowup">Followup</ToggleButton>
          <ToggleButton value="/addticket">Add Tickets</ToggleButton>
        </ToggleButtonGroup>
        <Typography variant="h4" sx={{ ml: 2}}>Tickets</Typography>
      </Stack>

      <div style={{ height: 500, width: '98%' }}>
        { dataloaded && (

        <DataGrid
          initialState={{
            sorting: {
              sortModel: [{ field: 'requestdate', sort: 'desc' }],
            },
          }}
          editMode="row"
          getRowId={(row) => row.id}
          rowsPerPageOptions={[5, 10, 20]}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rows={rows}
          columns={columns}
          processRowUpdate={processRowUpdate}
          onRowEditStart={rowEditStart}
          onProcessRowUpdateError={handleProcessRowUpdateError}
          rowModesModel={rowModesModel}
          onRowModesModelChange={handleRowModesModelChange}
          onRowEditStart={handleRowEditStart}
          onRowEditStop={handleRowEditStop}
          slots={{
            toolbar: EditToolbar,
          }}
          slotProps={{
            toolbar: { setRows, setRowModesModel },
          }}

        />
      )}

        {!!snackbar && (
          <Snackbar
            open
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            onClose={handleCloseSnackbar}
            autoHideDuration={6000}
          >
            <Alert {...snackbar} onClose={handleCloseSnackbar} />
          </Snackbar>
        )}
      </div>

    </>
  );}

export default Gettickets;
