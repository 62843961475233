import React, { useState } from 'react';
import AWS from 'aws-sdk';
import { Box, Paper } from "@mui/material";

const GetImage = ({filelink, filename, width}) => {
  const [ signedUrl, setSignedUrl ] = useState(null);

  AWS.config.update({
    accessKeyId: process.env.REACT_APP_ACCESS,
    secretAccessKey: process.env.REACT_APP_SECRET,
    region: process.env.REACT_APP_REGION,
  });


  const s3 = new AWS.S3();

  const params = {Bucket: 'cu1', Key: filelink, Expires: 60*5};
  //const params = {Bucket: 'cu1', Key: filename};
  s3.getSignedUrl('getObject', params, function (err, url) {
      setSignedUrl(url);
  });

  return (
    <Box><img src={signedUrl} width={width} /></Box>
  );
};

export default GetImage;
