import React, { useState, useEffect } from 'react';
import { Typography, Checkbox, FormControl, FormGroup, FormControlLabel, Stack } from "@mui/material";

const Sharewithwhom = (props) => {
  const [ sharearray, setShareArray] = useState([]);

  const [sharewith, setShareWith] = useState({
    students: false,
    faculty: false,
    staff: false,
  });  

  const handleCheckboxChange = (e) => {
    setShareWith({
      ...sharewith,
      [e.target.name]: e.target.checked,
    });
  };

  const { students, faculty, staff } = sharewith;

  useEffect(() => {
  	let newarray = [];
    if (students) newarray.push("students");
    if (faculty) newarray.push("faculty");
    if (staff) newarray.push("staff");
    //console.log(sharearray);
    setShareArray(newarray);
  }, [sharewith]);

  useEffect(() => {
    props.funcsharearray(sharearray);
  }, [sharearray]);

  return (
    <div align="left">

      <Stack direction="row" sx={{ fontSize: 14, justifyContent: 'space-between', width: '425px', ml: 1, pb: 2}}>
        <Typography sx={{ mt: 1}}>Share with:</Typography>
        <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
          <FormGroup row>
            <FormControlLabel control={<Checkbox checked={students} onChange={handleCheckboxChange} name="students" />} label="Students" />
            <FormControlLabel control={<Checkbox checked={faculty} onChange={handleCheckboxChange} name="faculty" />} label="Faculty" />
            <FormControlLabel control={<Checkbox checked={staff} onChange={handleCheckboxChange} name="staff" />} label="Staff" />
          </FormGroup>
        </FormControl>
      </Stack>

    </div>
  );
}

export default Sharewithwhom;