import { createTheme } from "@mui/material/styles";

const white = "#ffffff";
//const green = "#bfedad";

const Mtheme = createTheme({
    palette: {
        common: {
            white: `${white}`
        },
        primary: {
          light: "#112233",
          main: "#0BBB5F",
          dark: "#778899",
          contrastText: "#000"
        },
        secondary: {
          light: "#f0e6e6",
          main: "#c93434",
          dark: "#3c3c3c",
          contrastText: "#000"
        }
    },
});

export default Mtheme;
