import React, { useState, useEffect } from "react";
import { TextField, Button, Autocomplete, Select, MenuItem, InputLabel, FormControl, IconButton, Typography, Chip, Stack } from "@mui/material";
import { useQuery, useMutation, gql } from "@apollo/client";
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import CheckIcon from "@mui/icons-material/Check";
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

import { green, blue, red, yellow } from '@mui/material/colors';
import sortArray from 'sort-array';

const tagquery = gql`
  query tag {
    gettagoptionsposttype(posttype: ["story"]) {
      id
      tagoptionname
      tagcategory
      posttype
      accessgroups
      addedby
    }
  }
`;

const blogquery = gql`
  query blogs {
    getpostscats(accessgroups: ["staff","faculty"], posttype: ["blog"]) {
     id
     title
     author
     authoremail
     posttext
     position
     file {
      filename
      fileext
      fileuuid
      filedate
      filelink
     }
     accessgroups
     otherauthors
     posttype
     tags
     createdAt
  }
 }
`;


const mutatequery = gql`
  mutation addtag($tagoptionname: String, $tagcategory: String, $posttype: [String], $accessgroups: [String], $addedby: String) {
    newtagoption(tagoptionname: $tagoptionname, tagcategory: $tagcategory, posttype: $posttype, accessgroups: $accessgroups, addedby: $addedby) {
      tagcategory
      tagoptionname
    }
  }
`;


const Taglist = (props) => {
  const { data, loading, error } = useQuery(tagquery);
  const [ addtag, { data2, loading2, error2 } ] = useMutation(mutatequery, { refetchQueries: [{ query: tagquery }],});
  const [ rows, setRows ] = useState(null);
  const [ dataloaded, setDataLoaded ] = useState(false);
  const [ tags, setTags ] = useState([]);
  const [ chooseadd, setChooseAdd ] = useState("choose");
  const [ tagcats, setTagCats ] = useState([]);
  const [ selcat, setSelCat ] = useState(null);
  const [ seltags, setSelTags ] = useState(null);
  const [ newtag, setNewTag ] = useState();
  const [ errorselcat, setErrorSelCat ] = useState();
  const [ ranupdate, setRanUpdate ] = useState(false);
  const [ rowssort, setRowsSort] = useState([]);
  const [snackbar, setSnackbar] = useState(null);

  const getcat = (option) => {
    return rows.find(row => row.tagoptionname==option).tagcategory + " | " + option;
  }

  const handleCloseSnackbar = () => setSnackbar(null);

  const handleChooseAdd = (e, value) => {
    let message = "";
    setErrorSelCat(message);
    setChooseAdd(value);
    setSelCat("Select a category");
  }

  const handleChangeSelTags = (e, value) => {
    setSelTags(e.target.value);
  }

  const handleChangeNewTag = (e, value) => {
    setNewTag(e.target.value);
  }

  const uv = (oldarray, startvalue) => {
    const uniqueTags = startvalue;
    oldarray.map((item) => {
      if (uniqueTags.indexOf(item) === -1) {
          uniqueTags.push(item)
      }
    });
    return uniqueTags;
  }

  const handleChangeCategory = (e, value) => {
    //console.log("new category=", e.target.value);
    setSelCat(e.target.value);
  }

  const savenewtag = () => {
    let message = "";
    if (selcat===null || selcat=="Select a category") {
      message = "Please select a value for category.";
      setErrorSelCat(message);
      return;
    }
    setErrorSelCat(message);
    //console.log("selcat", selcat);
    //console.log("newtag", newtag);
    let posttype = ["blog","resource","communication"];
    let accessgroups = ["staff", "faculty"];
    let addedby = "michael.walker@collegeunbound.edu";
    //console.log("posttype", posttype);
    //console.log("accessgroups", accessgroups);
    //console.log("addedby", addedby);
    let tagoptionname = newtag;
    let tagcategory = selcat;
    addtag({  variables: {tagoptionname, tagcategory, posttype, accessgroups, addedby} });
    //addtag({  variables: {newtag, selcat} });
    setRanUpdate(true);
    setChooseAdd("choose");
    let newtaginfo = new Object();
    newtaginfo.tagoptionname = tagoptionname;
    newtaginfo.tagcategory = tagcategory;
    newtaginfo.posttype = posttype;
    newtaginfo.accessgroups = accessgroups;
    newtaginfo.addedby = addedby;

    setRows(rows => [...rows, newtaginfo]);
    setSnackbar({ children: 'Tag successfully added.', severity: 'success' });
  }

  const gettags = () => {
    setTags(rows.map((row) => (row.tagoptionname)));
    setTagCats(uv(rows.map((row) => row.tagcategory),["Select a category"]));
  }

  useEffect(() => {
    if (data) {
      setRows(data.gettagoptionsposttype);
      setDataLoaded(true);
    }
  }, [data]);

  useEffect(() => {
    console.log("Karen");
    //setRows(data.gettagoptions);
  }, [ranupdate]);

  useEffect(() => {
    if (dataloaded) {
      //console.log("Karen");
      gettags();
    }
  }, [dataloaded]);

  useEffect(() => {
    props.functags(seltags);
  }, [seltags, chooseadd]);

  useEffect(() => {
    props.funcnewtag(newtag);
  }, [newtag]);

  useEffect(() => {
    console.log(chooseadd);
  }, [chooseadd]);

  useEffect(() => {
    if (props.setthetags && props.setthetags.length>0) {
      setSelTags([...props.setthetags]);
    }
    else setSelTags([])
  }, [props.setthetags]);  

  useEffect(() => {
    if (rows) {
      let rowssort = sortArray([...rows], { by: ['tagcategory','tagoptionname'], order: 'asc'})
      setTags(rowssort.map((row) => (row.tagoptionname)));
      //console.log(sortArray([...rows], { by: ['tagcategory','tagoptionname'], order: 'asc'}));
    }
  }, [rows]);

  /*if (tagcat) {
    console.log(tagcat);
  }*/

  if (loading) {
    return <p>loading...</p>;
  }

  if (loading2) {
    console.log("loading2");
    return <p>loading2...</p>;
  }

  if (error) {
    //console.log(error)
    return <p>Ruh roh! {error.message}</p>;
  }

  const sortcats = (tagcats) => {
    let newtags = ['Select a category'];
    sortArray(tagcats).filter((f) => f!=='Select a category').map((item) => (
     newtags.push(item)
    ))
    return newtags;
  }


  return (
    <>
    <ToggleButtonGroup value={chooseadd} color="primary" exclusive size="small" onChange={handleChooseAdd}>
      <ToggleButton value="choose">Select Tags</ToggleButton>
      <ToggleButton value="add">Create a New Tag</ToggleButton>
    </ToggleButtonGroup>

    <br/><br/>

    { tags && chooseadd==="choose" && (

      <Select 
        value={seltags}
        renderValue={(selected) => (
          <Stack gap={1} direction="row" flexWrap="wrap">
            {selected.map((value) => (
              <Chip
                key={value}
                label={value}
                onDelete={() =>
                  setSelTags(
                    seltags.filter((item) => item !== value)
                  )
                }
                deleteIcon={
                  <CancelIcon
                    onMouseDown={(event) => event.stopPropagation()}
                  />
                }
              />
            ))}
          </Stack>
        )}
        onChange={handleChangeSelTags}
        sx={{ textAlign:"left", width:props.width }}
        multiple
        variant="standard"
      >
        {tags.map((option, index) => (
          <MenuItem key={index} value={option}>{option}</MenuItem>
        ))}
      </Select>

   )}


   { chooseadd==="add" ? (
     <div>
     <FormControl variant="standard" >
       <Select
         labelId="demo-simple-select-label"
         id="demo-simple-select"
         defaultValue="Select a category"
         onChange={handleChangeCategory}
         sx={{ ml:1, width: 225 }}
       >
         { tagcats && (
           sortcats(tagcats).map((item) => (
             <MenuItem key={item} value={item}>{item}</MenuItem>
           ))
         )}
       </Select>
       {errorselcat && <Alert severity="error">{errorselcat}</Alert>}
       </FormControl>
       <FormControl variant="standard" >
         <TextField name="tagoptionname" placeholder="Enter a tag" onChange={handleChangeNewTag} variant="standard" sx={{ ml: 5, width: 200}}></TextField>
       </FormControl>
       <IconButton onClick={savenewtag} sx={{ width: 20, height: 20, margin: 1, color: green[500] }}><SaveIcon/></IconButton>
       <IconButton name="cancel" sx={{ width: 20, height: 20 }} onClick={() => { setChooseAdd("choose")}}><CancelIcon /></IconButton>
       </div>
   ):(
     <></>
   )}

   {!!snackbar && (
     <Snackbar
       open
       anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
       onClose={handleCloseSnackbar}
       autoHideDuration={10000}
     >
       <Alert {...snackbar} onClose={handleCloseSnackbar} />
     </Snackbar>
   )}

   </>
  );
}
export default Taglist;
