import React, { useState, useEffect } from 'react';
import { Typography, Stack, Link, ToggleButton, ToggleButtonGroup, Select, MenuItem, TextField, Button, Box, Pagination, IconButton, FormHelperText, Divider } from "@mui/material";
import AWS from 'aws-sdk';
import { useQuery, useMutation, gql } from "@apollo/client";
import parser from 'html-react-parser';
import moment from 'moment';
import Taglistabbstories from '../components/Taglistabbstories';
import Grid from '@mui/material/Grid';
import Getuserinfo from "../components/Getuserinfo";
import GetImage from "../components/GetImage";
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { NavLink } from "react-router-dom";
import sortArray from 'sort-array';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { yellow, red } from '@mui/material/colors';

import "../components/formatlinks.css";

const blogquery = gql`
  query blogs {
    getpostscats(accessgroups: ["staff","faculty"], posttype: ["story"]) {
     id
     title
     posttext
     author
     authoremail
     otherauthors
     postimage {
      filename
      fileext
      fileuuid
      filedate
      filelink
     }
     file {
      filename
      fileext
      fileuuid
      filedate
      filelink
     }
     tags
     posttype
     accessgroups
     response {
      responderemail
      responsetext
      responsedate
     }
     position
     createdAt
     archive
    } 
  }
`;

const deletequery = gql`
  mutation removepost($id: ID) {
    deletepost (id: $id)
  }
`;

const archivequery = gql`
  mutation editpostarchive($id: ID, $archive: String) {
    updatepostarchive(id: $id, archive: $archive) {
      id
      archive
    }
  }`
;


function Getstories() {
  const { data, loading, error } = useQuery(blogquery);
  const [ removepost, { data2, loading2, error2 } ] = useMutation(deletequery, { refetchQueries: [{ query: blogquery }],});
  const [ editpostarchive, { data3, loading3, error3 } ] = useMutation(archivequery, { refetchQueries: [{ query: blogquery }],});
  const [ rows, setRows ] = useState(null);
  const [ rowsall, setRowsAll ] = useState([]);
  const [ numrows, setNumRows ] = useState(0);
  const [ dataloaded, setDataLoaded ] = useState(false);
  const [ gotolink, setGoToLink] = useState("/getstories");
  const [ seltags, setSelTags ] = useState([]);
  const [ authoremails, setAuthorEmails ] = useState([]);
  const [ authornames, setAuthorNames ] = useState([]);
  const [ selectedauthor, setSelectedAuthor ] = useState("");
  const [ clearthetags, setClearTheTags ] = useState(false);
  const [ tagstoset, setTagsToSet ] = useState([]);
  const [ tagstatus, setTagStatus ] = useState("tagsnotset");
  const [ selectedsubmitter, setSelectedSubmitter ] = useState("All Submitters");
  const [ ranchecksession, setRanCheckSession ] = useState(false);
  const [ pageSize, setPageSize ] = useState(3);
  const [ pagination, setPagination ] = useState({ count: 0, page: 1, from: 0, to: pageSize });
  const [ paginationlabel, setPaginationLabel ] = useState("");
  const [ page, setPage ] = useState(1);
  const [ sortby, setSortBy ] = useState("createdAt");

  const userinfo = useSelector((state) => state.userinfo);

  const navigate = useNavigate();

  /**************************** main filters  ****************************/

  // this retrieves the blogs according to seltags or selectedsubmitter values
  // if seltags has a value filter by the tags selected
  // if no tags filter by selected name
  const findblogs = () => {
    if (dataloaded && rowsall) {
      let rowstemp = [];
      console.log("rowsall=", rowsall);
      //console.log("seltags=", seltags);
      if(seltags.length>0 && selectedsubmitter==="All Submitters") {
        setRows(rowsall.filter((rowf) => rowf.tags.some(item => seltags.includes(item))));
        console.log("seltags=", seltags.length)
      } else if (seltags.length>0 && selectedsubmitter!=="All Submitters") {
        console.log("Karen");
        rowstemp = rowsall.filter((rowf) => rowf.tags.some(item => seltags.includes(item)));
        let selectedemail = getemail();
        setRows(rowstemp.filter((rowf) => rowf.authoremail===selectedemail));        
      } else {
        if (selectedsubmitter==="All Submitters") {
          setRows(rowsall);
        } else {
          let selectedemail = getemail();
          setRows(rowsall.filter((rowf) => rowf.authoremail===selectedemail));
        }
      }
    }    
  }

  function setsessionitems(id) {
    sessionStorage.setItem('blogtoview', id);
    sessionStorage.setItem('selectedsubmitter', selectedsubmitter);
    sessionStorage.setItem('seltags', [seltags]);
    sessionStorage.setItem('paginationto', pagination.to);
    sessionStorage.setItem('paginationfrom', pagination.from);
    sessionStorage.setItem('page', pagination.page);
    sessionStorage.setItem('pageSize', pageSize);
    sessionStorage.setItem('sortedby', sortby);
    sessionStorage.setItem('returntoviewblogs', "true");    
  }

  // navigate to the page that will show a particular blog post
  // add the id of the blog post to session storage so that the view blog page knows which one
  // also store the current find blog selection for later return
  function viewblog(id) {
    setsessionitems(id);
    navigate('/viewstory', { replace: true})
  }

  function editblog(id) {
    setsessionitems(id);
    navigate('/editstory', { replace: true})
  }

  function deleteblogserved(id) {
    removepost({  variables: { id } });
  }

  function deleteblog(id) {
    let archive = "Y";
    editpostarchive({  variables: { id, archive } });
    //findblogs();
    //setRows(rows.filter((rowf) => rowf.id!=id));
    setRanCheckSession(true);
  }


  // this runs on a return to the getblogs page after viewing a single blog
  // use the session values to restore the most recent search
  // set ranchecksession value to true to trigger a rerun of findblogs later
  const checksession = () => {
    if (sessionStorage.getItem("returntoviewblogs")==='true') {
      let sname = sessionStorage.getItem("selectedsubmitter");
      let stags = sessionStorage.getItem("seltags");
      setSortBy(sessionStorage.getItem("sortedby"));
      if (stags.length>0) {
        // set new values for tags in the dropbox; this will not change the seltags values
        setTagsToSet(stags.split(','));
        // set values for seltags
        setSelTags(stags.split(','));
      }

      if (sname && sname!=="All Submitters") {
        setSelectedSubmitter(sname);
      }

      pagination.to = parseInt(sessionStorage.getItem("paginationto"));
      pagination.from = parseInt(sessionStorage.getItem("paginationfrom"));
      //setPage(parseInt(sessionStorage.getItem("page")));
      pagination.page = parseInt(sessionStorage.getItem("page"))
      setPageSize(parseInt(sessionStorage.getItem("pageSize")));
      sessionStorage.removeItem("selectedsubmitter");
      sessionStorage.removeItem("seltags");
      sessionStorage.removeItem("returntoviewblogs");
      sessionStorage.removeItem("paginationto");
      sessionStorage.removeItem("paginationfrom");
      sessionStorage.removeItem("page");
      sessionStorage.removeItem("pageSize");
      sessionStorage.removeItem("sortedby");
      setRanCheckSession(true);
      //console.log("pagination=", pagination);
    }
  }

  // do initial setup of rows
  // also get author names and emails
  // this is triggered whenever number of rows (numrows) changes or rows changes
  // but only set rowsall before rows have been filtered, when there are the original number of rows
  // and only update author names and emails once
  const initializerows = () => {
    //if (rows) console.log("rows", rows, " rows.length=", rows.length, " numrows=", numrows);
    if (dataloaded && numrows===rows.length) {
      setRowsAll(rows);
    }
    if (dataloaded && rows.length>0 && authoremails.length===0) {
      setAuthorEmails(sortArray(uv(rows.map((row) => row.authoremail),[])));
      setAuthorNames(sortArray(uv(rows.map((row) => row.author),["All Submitters"])));
    }    
  }

  const getpagelabel = () => {
    if (dataloaded) {
      let displayfrom = pagination.from+1;
      let displayto = pagination.to;
      if (displayto > rows.length) displayto = rows.length;
      //console.log("Blogs " + displayfrom + " to " + displayto +  " out of " + rows.length);
      setPaginationLabel("Stories " + displayfrom + " to " + displayto +  " out of " + rows.length);
    }    
  }

  const setpagechange = (page, ps) => {
    const from = (page - 1) * ps;
    const to = (page - 1) * ps + ps;

    setPagination({...pagination, page: page, from: from, to: to })    
  }

  const handlePageChange = (e, page) => {
    setpagechange(page, pageSize);
  }


  /******************************** triggers ***************************/
  useEffect(() => {
    if (userinfo.length==0) {
      navigate("/", { replace: true})
    }
  }, []);  

  useEffect(() => {
    if (data) {
      //setRows(data.getpostscats);
      //setRowsAll(data.getpostscats);
      setRows(sortArray([...data.getpostscats], { by: ['createdAt'], order: 'desc'}))
      setRowsAll(sortArray([...data.getpostscats], { by: ['createdAt'], order: 'desc'}))
      setDataLoaded(true);
      //console.log("setrows");
   }
  }, [data]);

  useEffect(() => {
    if (dataloaded) {
      setRows(sortArray([...rows], { by: ['createdAt'], order: 'desc'}))
      setNumRows(rows.length);
    }
  }, [dataloaded]);

  useEffect(() => {
    initializerows();
    getpagelabel();
  }, [rows,numrows]);

  useEffect(() => {
    findblogs();
  }, [seltags]);

  useEffect(() => {
    findblogs();
  }, [selectedsubmitter]);

  // this will run after the initialization of rows
  useEffect(() => {
    if (rowsall && rowsall.length>0) {
      //getpagelabel();
      checksession();
    }
  }, [rowsall]);

  useEffect(() => {
    if (ranchecksession) {
      findblogs();
    } else {
      setRanCheckSession(false);
    }
  }, [ranchecksession]);

  useEffect(() => {
    //console.log("pagination=", pagination)
    getpagelabel();
  },[pagination.from, pagination.to, pageSize]);

  useEffect(() => {
    if (!dataloaded) return;
    console.log("sortby=", sortby)
    if (sortby==='createdAt') {
     setRows(sortArray([...rows], { by: ['createdAt'], order: 'desc'}))
    } else {
     setRows(sortArray([...rows], { by: ['position'], order: 'asc'}))
    }
  }, [sortby]);

  if (loading) {
    return <p>loading...</p>;
  }

  if (error) {
    //console.log(error)
    return <p>Ruh roh! {error.message}</p>;
  }

  /**************************************** helper functions ********************************/

  // show only the first 325 characters of a blog post
  const getWordStr = (mytext) => {
    let newvalue = mytext;
    if (newvalue.length>=325) {
      newvalue = newvalue.toString().substring(0,325) + ".....";
    }
    return newvalue;
  }

  const uv = (oldarray, startvalue) => {
    const uniqueValues = startvalue;
    oldarray.map((item) => {
      if (uniqueValues.indexOf(item) === -1) {
          uniqueValues.push(item)
      }
    });
    return uniqueValues;
  }

  const handleChangeLink = (e, value) => {
    setGoToLink(value);
    navigate(value, { replace: true})
  }

  const handleNameSelect = (e, value) => {
    setSelectedSubmitter(e.target.value);
    //setTagsToSet([]);
  }

  const gettags = (data) => {
    setTagStatus("tagsdeselected");
    setSelTags(data);
  }

  const handleFindClick = () => {
    findblogs();
    pagination.page = 1;
  }

  // get the e-mail address associated with the selected name
  const getemail = () => {
    let email = "";
    let sr = rowsall.filter((row) => row.author===selectedsubmitter);
    //console.log("sr=", sr);
    email = sr[0].authoremail;
    return email;
  }

  const handlePageSize = (e, value) => {
    console.log("e.target.value=", e.target.value);
    setPageSize(e.target.value);
    setpagechange(pagination.page, e.target.value);
  }


  return (
    <div align="left">
      <Stack direction="row" alignItems="center" sx={{ justifyContent: 'space-between', width: '500px', ml: 2, pb: 5 }}>
        <ToggleButtonGroup value={gotolink} color="primary" exclusive onChange={handleChangeLink}>
          <ToggleButton value="/getstories">View Stories</ToggleButton>
          <ToggleButton value="/addstory">Add Story</ToggleButton>
        </ToggleButtonGroup>
        <Typography variant="h4">CU Stories</Typography>
      </Stack>

      { dataloaded && (

      <Stack direction='row'>
        <Stack display="flex" alignItems="Left" direction='column' sx={{ bgcolor: '#EAFAF1', color: "white", height: 270, width: 350 }}>

          {/*<Box sx={{ fontSize: 16, fontWeight: 'Bold', ml: 3, mt: 2 }}>Find Blogs by Tags</Box>*/}
          <FormHelperText sx={{ fontSize: 16, fontWeight: 'Bold', ml: 3, mr: 2, mt: 2 }}>Tags</FormHelperText>

          <Box sx={{ ml: 3, mb: 2 }}>
            <Taglistabbstories functags={gettags} cleartags={clearthetags} setthetags={tagstoset} width={"310px"} />
          </Box>

          {/*<Box sx={{ fontSize: 16, fontWeight: 'Bold', ml: 3 }}>Find Blogs by Author</Box>*/}

          { authornames.length>0 && (

          <>
          <TextField type={'text'} value={selectedsubmitter} onChange={handleNameSelect} sx={{ ml: 3, mt: 3, mb: 3, textAlign:"left", width:310, fontSize: "10px"}} defaultValue="All Submitters" select multiple variant="standard">
            {authornames.map((option, index) => (
              <MenuItem key={index} value={option} sx={{fontSize: "12px"}} >{option}</MenuItem>
            ))}
          </TextField><br/>
          </>

          )}

          {/*<Button onClick={findblogs} sx={{ margin: 3, textAlign:"left", width: '125px', mt: 5 }} variant='contained'>Find Blogs</Button>*/}

          <Stack direction='row'>
            <Select value={sortby} onChange={(e) => setSortBy(e.target.value)} variant="standard" sx={{ ml: 3, width: 310 }} >
              <MenuItem value="createdAt">Sort by Posted Date</MenuItem>
              <MenuItem value="position">Sort by Position</MenuItem>
            </Select>
          </Stack>

        </Stack>

        <Grid container direction="column" align='left' width="575px" sx={{ml: 7}}>

        {rows.slice(pagination.from,pagination.to).map((post, index) => (
          <Grid item key={post.id} align="left">
            <Stack direction="row">
              <Link component="button" variant="h6" onClick={() => viewblog(post.id) }>{post.title}</Link>
              { (post.authoremail===userinfo.email || userinfo.email==='michael.walker@collegeunbound.edu') && (
                <>
                  <IconButton name="editblog" sx={{ color: yellow[700], width: 20, height: 20, ml: 2 }} onClick={() => editblog(post.id) }><EditIcon /></IconButton>
                  <IconButton name="deleteblog" sx={{ color: red[500], width: 20, height: 20, ml: 2 }} onClick={() => deleteblog(post.id) }><DeleteIcon /></IconButton>
                </>
              )}
            </Stack>


            <Stack spacing={5} direction="row">
              <Typography sx={{ width: 420 }}>{parser(getWordStr(post.posttext))}</Typography>
              { post.postimage.length>0 && (
              <Box>
                {post.postimage.map((f, index) => (
                  <>
                  <GetImage filelink={f.filelink} filename={f.filename} width="200"/>
                  </>
                ))}
              </Box>
              )}
            </Stack>
            <Typography sx={{ fontSize: 14}}>{"Tags: " + post.tags.join(" / ")}</Typography>
            <Typography sx={{ fontSize: 14}}>{ "Position: " + post.position }</Typography>
            <Typography sx={{ fontSize: 14}}>{ "Posted: " + moment(parseFloat(post.createdAt)).format('MM/DD/YY') }</Typography><br/>
            <Getuserinfo email={post.authoremail} first="avatar" /><br/><br/>
          </Grid>
        ))}

          <Grid item align="left">

            <Box sx={{ fontSize: 14, ml: 3, mb: 2 }}>{paginationlabel}</Box>


            <Stack direction="row">
              <Pagination count={Math.ceil(rows.length/pageSize)} page={pagination.page} onChange={handlePageChange} color="primary" />
              <FormHelperText sx={{ fontSize: 14, ml: 3, mr: 2 }}>Page Size</FormHelperText>
              <Select value={pageSize} onChange={handlePageSize} label="Page Size" sx={{height: 24}} >
                <MenuItem value={3}>3</MenuItem>
                <MenuItem value={5}>5</MenuItem>
                <MenuItem value={7}>7</MenuItem>
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={15}>15</MenuItem>
              </Select>
            </Stack>

          </Grid>


        </Grid>

      </Stack>

      )}
    
    </div>
  );


}

export default Getstories;
