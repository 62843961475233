import React, { useState, useEffect } from 'react';
import { Typography, TextField, Button, Slider, Box, ToggleButton, ToggleButtonGroup, Checkbox, FormGroup, FormControlLabel } from "@mui/material";
import { useMutation, gql } from "@apollo/client";
//import Container from 'react-bootstrap/Container';
import Grid from '@mui/material/Grid';
import Taglistabbstory from '../components/Taglistabbstory';
import Texteditor from '../components/Texteditor';
import Uploadfp from '../components/Uploadfp';
import Sharewithwhom from '../components/Sharewithwhom';
import GetSignedFileUrl from '../components/GetSignedFileUrl';
import Fab from '@mui/material/Fab';
import { NavLink } from "react-router-dom";
import Stack from '@mui/material/Stack';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import "../components/formatlinks.css";

//import Taglist from './Taglist';

//{ title, posttext, author, authoremail, otherauthors, postimage, file, tags, posttype, accessgroups, position }
const mutatequery = gql`
  mutation addpost($title: String, $posttext: String, $author: String, $authoremail: String, $otherauthors: [String], $postimage: [ImageInput], $file: [FileInput], $tags: [String], $posttype: [String], $accessgroups: [String], $position: Int) {
    newpost(title: $title, posttext: $posttext, author: $author, authoremail: $authoremail, otherauthors: $otherauthors, postimage: $postimage, file: $file, tags: $tags, posttype: $posttype, accessgroups: $accessgroups, position: $position) {
      author
      posttext
    }
  }`
;

const blogquery = gql`
  query blogs {
    getpostscats(accessgroups: ["staff","faculty"], posttype: ["story"]) {
     id
     title
     author
     authoremail
     posttext
     position
     postimage {
      filename
      fileext
      fileuuid
      filedate
      filelink
     }
     file {
      filename
      fileext
      fileuuid
      filedate
      filelink
     }
     accessgroups
     otherauthors
     posttype
     tags
     createdAt
  }
 }
`;

function Addstory() {
  const [ addpost, { data2, loading2, error2 } ] = useMutation(mutatequery, { refetchQueries: [{ query: blogquery }],});
  const [ title, setTitle] = useState();
  const [ filenames, setFileNames] = useState([]);
  const [ mainimage, setMainImage] = useState([]);
  const [ addfiles, setAddFiles] = useState(false);
  //const [ tags, setTags] = useState([]);
  const [ seltags, setSelTags ] = useState(null);
  const [ newtag, setNewTag] = useState();
  const [ sharearray, setShareArray] = useState();
  const [ posttext, setPostText] = useState();
  const [ position, setPosition] = useState(3);
  const activeStyle = { color: 'green' };
  const [ gotolink, setGoToLink] = useState("/addstory");

  const userinfo = useSelector((state) => state.userinfo);

  const navigate = useNavigate();

  const handleBlogSubmit = (e) => {
    e.preventDefault();
    console.log(e.target["title"].value);
  }

  const handleTitleChange = (e, value) => {
    setTitle(e.target.value);
  }

  const handleChangePosition = (event, value) => {
    setPosition(value);
  };

  const getposttext = (data) => {
    //console.log(data);
    //console.log("userinfo=", userinfo);
    setPostText(data);
  }

  const getfilenames = (data) => {
    setFileNames(data);
  }

  const getmainimage = (data) => {
    setMainImage(data);
  }

  const gettags = (data) => {
    console.log("tags=", data);
    setSelTags(data);
  }

  const getnewtag = (data) => {
    setNewTag(data);
  }

  const getsharearray = (data) => {
    setShareArray(data);
  }

  // save all of the information for the post
  const savepost = () => {
    let author = userinfo.name;
    let authoremail = userinfo.email;
    let otherauthors = ["None"];
    let file = filenames;
    let posttype = ["story"];
    let accessgroups = sharearray;
    let tags = seltags;
    let postimage = mainimage ;

    //console.log("filenames=", filenames);
    //console.log("tags=", tags);
    //console.log("postimage=", postimage);
    console.log("title=", title, "posttext=", posttext, "author=", author, "authoremail=", authoremail, "otherauthors=", otherauthors, "postimage=", postimage, "file=", file, "tags=", tags, "posttype=", posttype, "accessgroups=", accessgroups, "position=", position);

    addpost({  variables: { title, posttext, author, authoremail, otherauthors, postimage, file, tags, posttype, accessgroups, position } });

    navigate("/getstories", { replace: true})
  }

  useEffect(() => {
    console.log("mainimage=", mainimage);
  }, [mainimage]);

  useEffect(() => {
    //console.log("filenames=", filenames);
  }, [filenames]);

  useEffect(() => {
    console.log(sharearray);
  }, [sharearray]);

  const handleChangeLink = (e, value) => {
    setGoToLink(value);
    navigate(value, { replace: true})
  }

  return (
    <div align="left">

      <Stack direction="row" alignItems="center" sx={{ justifyContent: 'space-between', width: '500px', ml: 2, pb: 5 }}>
        <ToggleButtonGroup value={gotolink} color="primary" exclusive onChange={handleChangeLink}>
          <ToggleButton value="/getstories">View Stories</ToggleButton>
          <ToggleButton value="/addstory">Add Story</ToggleButton>
        </ToggleButtonGroup>
        <Typography variant="h5">Add Story</Typography>
      </Stack>

    <Grid container>
      <Grid item style={{ margin: "10px" }}>
          <TextField name="title" value={title} onChange={handleTitleChange} placeholder="Enter a title for the story." variant="standard" size="large" sx={{ m: 1, width: 700 }}></TextField><br/>
          <Texteditor func={getposttext} height="500px" width="700px" sx={{ m: 2}}/><br/>
      </Grid>
      <Grid item sx={{ m: 5 }}>
          <Taglistabbstory functags={gettags} funcnewtag={getnewtag} width={"375px"} /><br/><br/>
          <Stack spacing={1} direction="row" sx={{ justifyContent: 'space-between' }}>

            <Box sx={{ fontSize: 14, m: 1 }}>{"Position: " + position}</Box>
            <Slider
              aria-label="Temperature"
              defaultValue={3}
              value={position}
              valueLabelDisplay="auto"
              step={1}
              marks
              min={1}
              max={5}
              onChange={handleChangePosition}
              sx={{width: 200}}
            />
            <Box sx={{ fontSize: 11, ml: 6, display:'flex', alignItems:'center' }}>(Posts with lower numbers appear first.)</Box>


          </Stack><br/>

          <Sharewithwhom funcsharearray={getsharearray}/>

          <Box sx={{ ml: 1 }}>Upload Image</Box>
          <Uploadfp func={getmainimage}/>
            <br/><br/>

          <Box sx={{ ml: 1 }}>Add Files</Box>
          <Uploadfp func={getfilenames}/>
            <br/><br/>

          <Button onClick={savepost} variant='contained'>Save Story</Button>

      </Grid>

      <Grid item>
        {/*<GetSignedFileUrl filename="SP_2022_2027.pdf"/>*/}
      </Grid>

    </Grid>

    </div>
  );
}

export default Addstory;
