import React, { useState, useEffect } from 'react';
import { TextField, Typography, Button, Stack, Link, Box, ToggleButton, ToggleButtonGroup, Divider, Fab, IconButton } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import { useQuery, useMutation, gql } from "@apollo/client";
import { useParams } from "react-router";
import parser from 'html-react-parser';
import Grid from '@mui/material/Grid';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { NavLink } from "react-router-dom";
import { Formik, Field, Form } from "formik";
import Getuserinfo from "../components/Getuserinfo";
import GetImage from "../components/GetImage";
import GetSignedFileUrl from "../components/GetSignedFileUrl";
import Texteditor from '../components/Texteditor';
import { green, blue, red, yellow } from '@mui/material/colors';
import { useSelector } from 'react-redux';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

const blogquery = gql`
  query blog($idnumber: Int) {
    getuserblogs(idnumber: $idnumber) {
      id
      idnumber
      blogtitle
      blogdescription
    }
  }
`;

const mutatequery = gql`
  mutation edittheblog($id: ID, $blogtitle: String, $blogdescription: String) {
    updateblog(id: $id, blogtitle: $blogtitle, blogdescription: $blogdescription) {
      blogdescription
      blogtitle
    }
  }`
;

const mutatequeryadd = gql`
  mutation addblog($idnumber: Int, $blogtitle: String, $blogdescription: String) {
    newblog(idnumber: $idnumber, blogtitle: $blogtitle, blogdescription: $blogdescription) {
      idnumber
      blogtitle
      blogdescription
    }
  }
`;

const deletequery = gql`
  mutation removeblog($id: ID) {
    deleteblog (id: $id)
  }
`;



function Yourblog() {
  let idnumber = sessionStorage.getItem("otherinfo")*1;
  //let stags = sessionStorage.getItem("seltags");
  //console.log(idnumber)

  const { data, loading, error } = useQuery(blogquery, { variables: { idnumber } });
  const [ edittheblog, { data2, loading2, error2 } ] = useMutation(mutatequery, { refetchQueries: [{ query: blogquery, variables: { idnumber } }],});
  const [ addblog, { data3, loading3, error3 } ] = useMutation(mutatequeryadd, { refetchQueries: [{ query: blogquery, variables: { idnumber } }],});
  const [ removeblog, { data4, loading4, error4 } ] = useMutation(deletequery, { refetchQueries: [{ query: blogquery, variables: { idnumber } }],});
  const [ rows, setRows ] = useState(null);
  const [ dataloaded, setDataLoaded ] = useState(false);
  const [ showedit, setShowedit ] = useState([false,false,false,false,false,false,false]);
  const [ gotolink, setGoToLink] = useState("/yourblog");
  const [ redraw, setRedraw ] = useState(1);
  const [ showNew, setShowNew ] = useState(false);
  const userinfo = useSelector((state) => state.userinfo);

  const navigate = useNavigate();

  useEffect(() => {
    if (data) {
      setRows(data.getuserblogs);
      setDataLoaded(true);
      //console.log(data.getuserblogs);
    }
  }, [data]);

  useEffect(() => {
    if (userinfo.length==0) {
      navigate("/", { replace: true})
    } else {
      console.log("userinfo=", userinfo)
    }
  }, []);

  if (loading) {
    return <p>loading...</p>;
  }

  if (error) {
    return <p>Ruh roh! {error.message}</p>;
  }

  const handleChangeLink = (e, value) => {
    setGoToLink(value);
    navigate(value, { replace: true})
  }  
  
  const handleSubmit = (e) => {
    e.preventDefault();
    const id = e.target["id"].value;
    const blogtitle = e.target["blogtitle"].value
    const blogdescription = e.target["blogdescription"].value
    const index = e.target["index"].value;

    console.log("id=", id, "blogtitle=", blogtitle, "blogdescription=", blogdescription);
    edittheblog({  variables: {id, blogtitle, blogdescription} });
    editblog(index);
  }

  const handleSubmitNew = (e) => {
    e.preventDefault();
    const blogtitle = e.target["blogtitle"].value
    const blogdescription = e.target["blogdescription"].value

    console.log("idnumber=", idnumber, "blogtitle=", blogtitle, "blogdescription=", blogdescription);
    addblog({  variables: {idnumber, blogtitle, blogdescription} });
    setShowNew(!showNew);
  }


  function editblog(id) {
    let newarray = showedit;
    newarray[id] = !newarray[id];
    setShowedit(newarray);
    setRedraw(redraw+1);
  }

  function deleteblog(id) {
    removeblog({  variables: {id} });    
    setRedraw(redraw+1);
  }

  const addblogform = () => {
    setShowNew(!showNew);
  }  

  return (
    <div align="left">

      <Stack direction="row" alignItems="center" sx={{ justifyContent: 'space-between', width: '600px', ml: 2, pb: 5 }}>
        <ToggleButtonGroup value={gotolink} color="primary" exclusive onChange={handleChangeLink}>
          <ToggleButton value="/getblogs">View Blogs</ToggleButton>
          <ToggleButton value="/addblogpost">Add Blog Post</ToggleButton>
          <ToggleButton value="/yourblog">Your Blogs</ToggleButton>
        </ToggleButtonGroup>
        <Typography variant="h4">{userinfo.given_name}'s Blogs</Typography>
      </Stack>

      { dataloaded && showedit && (

      <Grid container direction="column" align='left' width="1000px" sx={{ p: 5}}>
        <Grid item align="left">

          {rows.map((f, index) => (

          <Grid item key={f.id} align="left">

            { !showedit[index] ? (
            <>
            <Stack direction="row">
              <Typography sx={{ fontSize: 18, fontWeight: 700}}>{f.blogtitle}</Typography>
              <IconButton name="editblog" sx={{ color: yellow[700], width: 20, height: 20, ml: 2 }} onClick={() => editblog(index) }><EditIcon /></IconButton>
              <IconButton name="deleteblog" sx={{ color: red[500], width: 20, height: 20, ml: 2 }} onClick={() => deleteblog(f.id) }><DeleteIcon /></IconButton>
            </Stack>
            <Stack direction="column">
              <Typography sx={{ fontSize: 14, width: 420 }}>{f.blogdescription}</Typography>
            </Stack><br/>
            </>

            ) : (

            <>
            <Stack direction="column">
              <br/>
              <form onSubmit={handleSubmit}>
                <Stack direction="row">
                  <IconButton type='submit' sx={{ width: 30, height: 30, color: green[500] }}><SaveIcon/></IconButton>
                  <IconButton name="cancel" sx={{ width: 30, height: 30 }} onClick={() => editblog(index)}><CancelIcon /></IconButton>
                </Stack><br/>
                <TextField name={"blogtitle"} key={"blogtitle"+f.id} defaultValue={f.blogtitle} type={'text'} label="Blog Title" sx={{ width: { sm: 200, md: 525 }}}></TextField><br/><br/>         
                <TextField name={"blogdescription"} key={"blogdescription"+f.id} defaultValue={f.blogdescription} type={'text'} multiline minRows={3} label="Blog Description" sx={{ width: { sm: 200, md: 525 }}}></TextField><br/>           
                <TextField name={"id"} key={"id"+f.id} defaultValue={f.id} type={'text'} sx={{ display: 'none' }}></TextField>
                <TextField name={"index"} key={"index"+index} defaultValue={index} type={'text'} sx={{ display: 'none' }}></TextField>
              </form>
            </Stack><br/>
            </>
            )}


          </Grid>


          ))}

          { showNew ? (

          <>
          <Stack direction="column">
            <br/>
            <form onSubmit={handleSubmitNew}>
              <Stack direction="row">
                <IconButton type='submit' sx={{ width: 30, height: 30, color: green[500] }}><SaveIcon/></IconButton>
                <IconButton name="cancel" sx={{ width: 30, height: 30 }} onClick={addblogform}><CancelIcon /></IconButton>
              </Stack><br/>
              <TextField name={"blogtitle"} key={"blogtitlenew"} type={'text'} label="Blog Title" sx={{ width: { sm: 200, md: 525 }}}></TextField><br/><br/>         
              <TextField name={"blogdescription"} key={"blogdescriptionnew"} type={'text'} multiline minRows={3} label="Blog Description" sx={{ width: { sm: 200, md: 525 }}}></TextField><br/>           
            </form>
          </Stack><br/>
          </>

          ) : (

          <Fab color="primary" variant="extended" aria-label="add" onClick={addblogform}>
            <AddIcon />Create Blog
          </Fab>

          )}


        </Grid>
      </Grid>                                   

      )}
    
    </div>
  );


}
                                                                     
export default Yourblog;


