import React, { useState, useEffect } from "react";
import { useQuery, useMutation, gql } from "@apollo/client";
import { DataGrid, GridActionsCellItem, gridClasses, GridRowModes, GridToolbarContainer } from '@mui/x-data-grid';
import PropTypes from 'prop-types';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import { TextField, Button, Typography, Box, Divider, Fab, ToggleButton, ToggleButtonGroup } from "@mui/material";
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Link } from "react-router-dom";
import Stack from '@mui/material/Stack';
import moment from 'moment';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { green, blue, red } from '@mui/material/colors';
import Grid2 from '@mui/material/Unstable_Grid2';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useNavigate } from 'react-router-dom';


const ticketfollowupquery = gql`
  query ticketfollowup {
    gettickets {
      id
      requestby
       problemdescription
       problemnotes
       requestdate
       assignedto
       category
       status
       followup {
         id
         followupdate
         followupnotes
       }
    }
  }
`;

const mutatequery = gql`
  mutation editticket($id: ID, $problemdescription: String, $problemnotes: String, $status: String) {
    updateticket (id: $id, problemdescription: $problemdescription, problemnotes: $problemnotes, status: $status) {
      id
      problemdescription
      problemnotes
      status
    }
  }
`;

const mutatequery3 = gql`
  mutation editticketfollowup($id: ID, $followupid: String, $followupnotes: String, $followupdate: String) {
    updateticketfollowup (id: $id, followupid: $followupid, followupnotes: $followupnotes, followupdate: $followupdate) {
      followupnotes
      followupdate
    }
  }
`;


const mutatequerynewfollowup = gql`
  mutation addticketfollowup($id: ID, $followupnotes: String, $followupdate: String) {
    newticketfollowup (id: $id, followupnotes: $followupnotes, followupdate: $followupdate) {
      followup {
        followupnotes
        followupdate
      }
    }
  }
`;

const mutatequeryremovefollowup = gql`
  mutation removeticketfollowup($id: ID, $followupid: String) {
    deleteticketfollowup (id: $id, followupid: $followupid)
  }
`;

function Getticketfollowup() {
  const { data, loading, error } = useQuery(ticketfollowupquery);
  const [pageSize, setPageSize] = useState(5);
  const [rowId, setRowId] = useState(null);
  const [snackbar, setSnackbar] = useState(null);
  const [rows, setRows] = useState(null);
  const [rowsFollowup, setRowsFollowup] = useState(null);
  //const [rowsFollowupFilter, setRowsFollowupFilter] = useState(null);
  const [folArray, setFolArray] = useState(null);
  //const [ticArray, setTicArray] = useState(null);
  const [dataloaded, setDataLoaded] = useState(false);
  const [ editticket, { data2, loading2, error2 } ] = useMutation(mutatequery);
  const [ editticketfollowup, { data3, loading3, error3 } ] = useMutation(mutatequery3, { refetchQueries: [{ query: ticketfollowupquery }],});
  const [ addticketfollowup, { data4, loading4, error4 } ] = useMutation(mutatequerynewfollowup, { refetchQueries: [{ query: ticketfollowupquery }],});
  const [ deleteticketfollowup, { data5, loading5, error5 } ] = useMutation(mutatequeryremovefollowup, { refetchQueries: [{ query: ticketfollowupquery }],});
  //const [ addticketfollowup, { data4, loading4, error4 } ] = useMutation(mutatequerynewfollowup);
  const [currentid, setCurrentId] = useState(null);
  const [ ticketUpdate, setTicketUpdate] = useState(false);
  const [ butOn, setButOn ] = useState([]);

  const [ butOn2, setButOn2 ] = useState([true,true,true,true,true,true,true,true,true,true]);
  const [ butVis, setButVis ] = useState(1);
  const [ folCnt, setFolCnt] = useState(0);
  const [ showNew, setShowNew ] = useState(false);
  const [ gotolink, setGoToLink] = useState("/getticketfollowup");

  const navigate = useNavigate();

  const handleClick = (event, cellValues) => {
    //console.log(rowsFollowup);
    setRowId(cellValues.id)
    setRowsFollowup(rows.filter((rowf) => rowf.id === cellValues.id))
    setTicketUpdate(false);

    //console.log(rowId + " " + cellValues.id);
    //console.log(rowsFollowupFilter);
  };

  const initButtons = () => {
    let butjson = [];
    rowsFollowup[0].followup.map((fol) => (
      butjson.push({ "id": fol.id, "show": false})
    ))
    setButOn(butjson);
    console.log(butOn);
    setButOn2([true,true,true,true,true,true,true,true,true,true]);
    //console.log(butOn2[0]);
    //console.log(butOn.find(b => b.id=="6430800bab58d25ab7e9581f"));
    //console.log(butOn.filter(b => b.id=="6430800bab58d25ab7e9581f"));
  }


  const columns = [
    {
      field: "id",
      headerName: "View Details",
      renderCell: (cellValues) => {
        return (
          <Button
            variant="text"
            color="primary"
            size="small"
            onClick={(event) => {
              handleClick(event, cellValues);
            }}
          >
            Details
          </Button>
        );
      }
    },
    { field: 'requestby', headerName: 'Requested By', width: 150},
    {
      field: 'requestdate', headerName: 'Date', width: 100, type: 'date',
      valueGetter: ({ value }) => value && new Date(value),
      renderCell: (params) =>
        moment(params.row.requestdate).format('MM/DD/YY'),
    },

  ]


  const handleCloseSnackbar = () => setSnackbar(null);

  useEffect(() => {
    console.log("rowId " + rowId)
  }, [rowId]);

  useEffect(() => {
    if (data) {
      setRows(data.gettickets);
      setRowsFollowup(data.gettickets);
      setDataLoaded(true);
    }
  }, [data]);

  useEffect(() => {
    if (dataloaded) {
      //console.log(rowsFollowup[0].followup);
      setFolArray(rowsFollowup[0].followup);
      //setTicArray(rowsFollowup[0]);
      //console.log(rowsFollowup[0]);
      initButtons();
    }
  }, [rowsFollowup]);

  /*useEffect(() => {
    console.log("butOn2=" + butOn2)
  }, [butOn2])*/


  if (loading) {
    return <p>loading...</p>;
  }

  if (error) {
    //console.log(error)
    return <p>Ruh roh! {error.message}</p>;
  }

  const handleTicketSubmit = (e) => {
    e.preventDefault();
    //console.log(rowsFollowup[0].id + "=" + e.target["id"].value);
    //console.log(e.target["status"].value);
    let id = rowsFollowup[0].id;
    let problemdescription = e.target["description"].value;
    let problemnotes = e.target["notes"].value;
    let status = e.target["status"].value;
    editticket({  variables: {id, problemdescription, problemnotes, status} });
    console.log("id=" + id + " problemdescription=" + problemdescription + " problemnotes=" + problemnotes + " status=" + status);
  }

  const handleFollowupSubmit = (e) => {
    e.preventDefault();
    const followupid = e.target[0].name.substring(5);
    let id = rowsFollowup[0].id;
    let followupnotes = e.target["notes"+followupid].value;
    let followupdate = e.target["date"+followupid].value;

    editticketfollowup({  variables: {id, followupid, followupnotes, followupdate} });
    console.log("id=" + id + " followupid=" + followupid + " followupnotes=" + followupnotes + " followupdate=" + followupdate);

    setButOn2([true,true,true,true,true,true,true,true,true,true]);

    //console.log(followupid);
    // console.log(e.target["notes" + followupid].value);
  }

  const handleFollowupSubmitNew = (e) => {
    e.preventDefault();
    let id = rowsFollowup[0].id;
    let followupnotes = e.target["notesnew"].value;
    let followupdate = e.target["datenew"].value;

    addticketfollowup({  variables: {id, followupnotes, followupdate} });
    console.log("id=" + id + " followupnotes=" + followupnotes + " followupdate=" + followupdate);
    setShowNew(false);

    //console.log(followupid);
    // console.log(e.target["notes" + followupid].value);
  }

  const handleTicketChange = (e) => {
    setTicketUpdate(true);
    //console.log("Karen="+e.target.value);
  }

  const handleFollowupChange = (e) => {
    let followupid = "";
    let newarray = [true,true,true,true,true,true,true,true,true,true];
    if (e.target.name.substring(0,5)==="notes") {
      followupid = e.target.name.substring(5);
    } else {
      followupid = e.target.name.substring(4);
    }

    for (let i = 0; i < butOn.length; i++) {
      if (butOn[i].id==followupid) {
        butOn[i].show = true;
        newarray[i] = false;
      }
    }
    setButOn(butOn);
    setButOn2(newarray);
    //setButOn2([false,true,true]);
    //console.log(butOn2);
    //butcheck(followupid);
  }

  //console.log(data.gettickets)
  const addFollowup = () => {
    console.log("Karen");
    setShowNew(true);
  }

  const removeFollowup = (e) => {
    //console.log(e.target.name);
    const followupid = e.target.name.substring(6);
    let id = rowsFollowup[0].id;
    deleteticketfollowup({  variables: {id, followupid} });
    console.log(id);
    console.log(followupid);
  }

  const butcheck = (followupid) => {
    //setButVis(butVis+1);
    let dis = true;
    if (butOn.find(b => b.id==followupid).show==true) {
      dis = false
    }
    //console.log(dis + "=" + followupid)
    //console.log(butOn);
    return dis
    /*if (followupid=="6430603a7d274ce71db2d4d1") {
      return true;
    } else {
      return false;
    }*/
  }

  const handleChangeLink = (e, value) => {
    setGoToLink(value);
    navigate(value, { replace: true})
  }


  return (
    <>
    <Stack direction="row" alignItems="center" sx={{ justifyContent: 'space-between', width: '700px', ml: 2, pb: 5 }}>
      <ToggleButtonGroup value={gotolink} color="primary" exclusive onChange={handleChangeLink}>
        <ToggleButton value="/gettickets">Tickets</ToggleButton>                                                                                                                                    
        <ToggleButton value="/getticketfollowup">Followup</ToggleButton>
        <ToggleButton value="/addticket">Add Tickets</ToggleButton>
      </ToggleButtonGroup>
      <Typography variant="h4" sx={{ ml: 2}}>Ticket Followup</Typography>
    </Stack>

    <TableContainer component={Paper}>
    <Table sx={{ minWidth: 650 }}>
      <TableBody>
      <TableRow>
        <TableCell style={{ verticalAlign: 'top' }}>

        { dataloaded && (

        <div style={{ height:800, width: 400}}>

        <DataGrid
          initialState={{
            sorting: {
              sortModel: [{ field: 'requestdate', sort: 'desc' }],
            },
          }}
          getRowId={(row) => row.id}
          rowsPerPageOptions={[5, 10, 20]}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          disableRowSelectionOnClick
          rows={rows}
          columns={columns}
        />

        </div>
      )}
      </TableCell>
      <TableCell style={{ verticalAlign: 'top', textAlign: 'left' }}>

      { rowsFollowup && (

            <form onSubmit={handleTicketSubmit}>
              <Typography color="primary" variant="h5">{rowsFollowup[0].requestby}</Typography>
              <Typography variant="h7">{moment(rowsFollowup[0].requestdate).format('MM/DD/YY')}</Typography><br/><br/>
              <TextField name="description" key={"description"+rowsFollowup[0].id} onChange={handleTicketChange} defaultValue={rowsFollowup[0].problemdescription} type={'text'} label="Description" sx={{ width: { sm: 200, md: 400 }}}></TextField><br/><br/>
              <TextField name="notes" key={"notes"+rowsFollowup[0].id} onChange={handleTicketChange} defaultValue={rowsFollowup[0].problemnotes} label="Notes" multiline minRows={3} type={'text'} sx={{ width: { sm: 200, md: 400 }}}></TextField><br/><br/>
              <Select
                name="status"
                key={"status"+rowsFollowup[0].id}
                defaultValue={rowsFollowup[0].status}
                onChange={handleTicketChange}
              >
                <MenuItem value={"Not started"}>Not started</MenuItem>
                <MenuItem value={"In process"}>In process</MenuItem>
                <MenuItem value={"Waiting for information"}>Waiting for information</MenuItem>
                <MenuItem value={"Done"}>Done</MenuItem>
              </Select>
              <Button name='butticket' type='submit' disabled={!ticketUpdate}>Update</Button>
              <TextField name="id" key={"id"+rowsFollowup[0].id} defaultValue={rowsFollowup[0].id} type={'text'} sx={{ display: 'none' }}></TextField>
              <br/><br/><br/>
            </form>

      )}


      { folArray && (

          <div>
            {folArray.map((fol, index) => (
              <div key={fol.id}>
              <form onSubmit={handleFollowupSubmit}>
                <TextField name={"notes"+fol.id} defaultValue={fol.followupnotes} onChange={handleFollowupChange} label="Followup Notes" multiline minRows={3} type={'text'} sx={{ width: { sm: 200, md: 350, margin: 7 }}}></TextField>
                <TextField name={"date"+fol.id} defaultValue={dayjs(fol.followupdate).format('YYYY-MM-DD')} onChange={handleFollowupChange} label="Followup Date" type={'date'} sx={{ width: { sm: 200, md: 200, margin: 7 }}}></TextField>
                <TextField name="id" key={"id"+fol.id} defaultValue={fol.id} type={'text'} sx={{ display: 'none' }}></TextField>
                <Button name={"but"+fol.id} type='submit' disabled={butOn2[index]}>Update</Button>
                <Button name={"butdel"+fol.id} onClick={removeFollowup} color="secondary">Del</Button><br/><br/>
              </form>
              </div>
            ))}
          </div>

      )}

      { showNew ? (

          <div>
              <form onSubmit={handleFollowupSubmitNew}>
                <TextField name={"notesnew"} defaultValue=" " label="Followup Notes" multiline minRows={3} type={'text'} sx={{ width: { sm: 200, md: 350, margin: 7 }}}></TextField>
                <TextField name={"datenew"} defaultValue={dayjs(new Date()).format('YYYY-MM-DD')} label="Followup Date" type={'date'} sx={{ width: { sm: 200, md: 200, margin: 7 }}}></TextField>
                <Button name={"butnew"} type='submit'>Add New</Button><br/><br/>
              </form>
          </div>

      ) : (

        <Fab color="primary" aria-label="add" onClick={addFollowup}>
          <AddIcon />
        </Fab>

      )}


        {!!snackbar && (
          <Snackbar
            open
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            onClose={handleCloseSnackbar}
            autoHideDuration={6000}
          >
            <Alert {...snackbar} onClose={handleCloseSnackbar} />
          </Snackbar>
        )}
        </TableCell>
        </TableRow>
      </TableBody>
  </Table>

    </TableContainer>


    </>
  );}

export default Getticketfollowup;
