import React, { useState, useEffect } from 'react';
import { Typography, TextField, Button, Slider, Box, ToggleButton, ToggleButtonGroup, Checkbox, FormGroup, FormControlLabel } from "@mui/material";
import { useQuery, useMutation, gql } from "@apollo/client";
//import Container from 'react-bootstrap/Container';
import Grid from '@mui/material/Grid';
import Fab from '@mui/material/Fab';
import sortArray from 'sort-array';
import { NavLink } from "react-router-dom";
import Stack from '@mui/material/Stack';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import "../components/formatlinks.css";

//import Taglist from './Taglist';

const mutatequery = gql`
  mutation addpost($title: String, $posttext: String, $author: String, $authoremail: String, $otherauthors: [String], $file: [FileInput], $tags: [String], $posttype: [String], $accessgroups: [String], $position: Int) {
    newpost(title: $title, posttext: $posttext, author: $author, authoremail: $authoremail, otherauthors: $otherauthors, file: $file, tags: $tags, posttype: $posttype, accessgroups: $accessgroups, position: $position) {
      author
      posttext
    }
  }`
;

const blogquery = gql`
  query blog($idnumber: Int) {
    getuserblogs(idnumber: $idnumber) {
      id
      idnumber
      blogtitle
      blogdescription
    }
  }
`;


const blogquery2 = gql`
  query blogs {
    getpostscats(accessgroups: ["staff","faculty"], posttype: ["blog"]) {
     id
     title
     author
     authoremail
     posttext
     position
     file {
      filename
      fileext
      fileuuid
      filedate
      filelink
     }
     accessgroups
     blog
     otherauthors
     posttype
     tags
     createdAt
  }
 }
`;

const blog2 = gql`
  query blogs2 {
   getblogs {
    blogtitle
    blogdescription
  }
}
`;


const blognamequery = gql`
  query blog($idnumber: Int) {
    getuserblogs(idnumber: $idnumber) {
      id
      idnumber
      blogtitle
      blogdescription
    }
  }
`;

function Testing() {
  let idnumber = sessionStorage.getItem("otherinfo")*1;
  //console.log(idnumber);
  //const { data: data6, loading: loading6, error: error6 } = useQuery(blogquery, { variables: { idnumber } });
  const dataq = useQuery(blogquery, { variables: { idnumber } });
  const [ gotolink, setGoToLink] = useState("/addblogpost");

  const userinfo = useSelector((state) => state.userinfo);

  const navigate = useNavigate();

  console.log("dataq.data=", dataq.data);

  const handleChangeLink = (e, value) => {
    setGoToLink(value);
    navigate(value, { replace: true})
  }

  return (
    <div align="left">

      <Stack direction="row" alignItems="center" sx={{ justifyContent: 'space-between', width: '600px', ml: 2, pb: 5 }}>
        <ToggleButtonGroup value={gotolink} color="primary" exclusive onChange={handleChangeLink}>
          <ToggleButton value="/getblogs">View Blogs</ToggleButton>
          <ToggleButton value="/addblogpost">Add Blog Post</ToggleButton>
          <ToggleButton value="/yourblog">Your Blogs</ToggleButton>
        </ToggleButtonGroup>
        <Typography variant="h5">Add Post</Typography>
      </Stack>

    </div>
  );
}

export default Testing;
